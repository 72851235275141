<template>
	<div class="vh-100">
		<div v-if="schedulings.length" class="container-fluid p-5" id="report-print">
			<div class="row">
				<div class="col-12">
					<div>
						<table class="w-100">
							<thead>
								<tr>
									<td colspan="5">
										<div class="row align-items-center">
											<div class="col-12">
												<img :src="organization_logo" height="70">
											</div>
											<div class="col-12 my-4">
												<b>
													RELATÓRIO: {{ selected.mode === 1 ? 'ATENDIMENTO' : 'FATURAMENTO'}}
												</b>
												<br>
												<b>
													PERIODO: {{ FormatDate(selected.date_init) }} ATÉ {{ FormatDate(selected.date_end)}}
												</b>
											</div>
										</div>								
									</td>
								</tr>
							</thead>
							<tfoot>
								<tr>
									<td colspan="5">
										<p class="text-right mt-4">
											<b>IMPRESSO EM:</b> {{ today }}
										</p>
									</td>
								</tr>
							</tfoot>
							<tbody>
								<tr>
									<td colspan="5">
										<table class="table w-100 table-sm">
											<thead>
												<tr>
													<th v-for="header in headers" :key="header.value">
														{{ header.text }}
													</th>
												</tr>
											</thead>
											<tbody>
												<tr 
													v-for="(item, index) in schedulings" 
													:key="index"
												>
													<td 
														v-for="(header, index_2) in headers"
														:key="index_2"
													>
														<template v-if="header.value == 'scheduling_status'">
															{{ GetStatus(item[header.value]) }}
														</template>
														<template v-else-if="header.value.substring(header.value.length - 10) == '_timestamp'">
															{{ item[header.value] }}
														</template>
														<template v-else-if="header.value == 'proceeding_value'">
															R$ {{ item[header.value] && item[header.value].indexOf(',') > -1 ? item[header.value] : ParseMoney(item[header.value]) }}
														</template>
														<template v-else>
															{{ item[header.value] }}
														</template>
												</td> 
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="h-100 d-flex justify-content-center align-items-center">
			<p class="font-32">
				Carregando...
			</p>
		</div>
	</div>
</template>

<script>
	import Helpers from '../../helpers'
	import moment from 'moment'
	const helpers 		= new Helpers()

	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'PrintReport',

		data () {
			return {
				schedulings: 		[],
				organization_logo: 	null,
				selected: 			{
					mode:               1, //1 - ATENDIMENTO, 2 - FATURAMENTO, 3 - AMBOS
                    date_init:          null,
                    date_end:           null,
					modalities:			[],
                    status:             []
				},
				headers: 			[]
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id', 'requesters'
			]),
			scheduling_status()
            {
                return [
                    /*{ name: "AGENDADO", value: 1 },
                    { name: "CONFIRMADO", value: 2 },
                    { name: "ESPERA", value: 3 },*/
                    { name: "CHAMADO", value: 4 },
                    { name: "ATENDIMENTO", value: 5 },
                    { name: "PENDENTE", value: 6 },
                    { name: "ATENDIDO", value: 7 },
                    { name: "FINALIZADO", value: 8 },
                    //{ name: 'ENTREGUE', value: 9 },
                    //{ name: 'ENTREGUE[2]', value: 10 },
                    /*{ name: "FALTA", value: 11 },
                    { name: "CANCELADO[DOC]", value: 12 },
                    { name: "CANCELADO[AGD]", value: 13 },
                    { name: "CANCELADO[ATD]", value: 14 },
                    { name: "CANCELADO[ECX]", value: 17 },*/
                    // { name: 'RETORNO', value: 15 },
                    { name: "ENCAIXE", value: 16 }
                ]
            },
			instance_config() {
				const requester = this.requesters.find((r) => r.id == this.requester_selected_id)

				if(requester) {
					return requester.instance.config
				}

				return null
			},
			today() {
				return moment().format('DD/MM/YYYY')
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
            GetSchedulingsToReport() {
                this.StartLoading()

                this.schedulings        = []

                return window.api.call('post', '/get-schedulings-to-report', {
                        account_id:         this.requester_selected_id,
                        ...this.selected,
						sort_by:			this.sortedBy
                    })
                    .then(({data}) => {
                        if(data.response) {
							if(this.search) {
								this.schedulings = data.items.filter(item => {
									return Object.values(item).some(value => String(value).toLowerCase().includes(this.search.toLowerCase()))
								})
							} else {
								this.schedulings        = data.items
							}  

							setTimeout(() => {
			                    window.print()
			                }, 1000)
						} else {
							setTimeout(()=>{
			                    window.close()
			                }, 1000) 

                            Swal.fire({
                                title:      'Opss...',
                                text:       'Houve um erro ao obter, contate o suporte.',
                                icon:       'error'
                            })
                        }
                    })
                    .finally(() => {
                        this.FinishLoading()
                    })
            },
			FormatDate(date)
			{
				return helpers.FormatDate(date)
			},
            GetStatus(status)
            {
                const item    = this.scheduling_status.find( sch => sch.value === status)

                return item ? item.name : status
            },
            ParseMoney(value) {
                return `${parseFloat(value).toFixed(2)}`.replace('.', ',')
            },
		},
		async mounted()
		{
			// Prepending the @media print query for priority
			const style_print = window.jQuery('<style>@media print { body { zoom: 40% } @page { size: A4 landscape !important; } }<style>');
			style_print.prependTo('head');

			window.addEventListener('afterprint', () => {
	            if (window.mainWindow)
	            {
	            	window.mainWindow.postMessage({ print: true })
	            }

	            window.close()
	        })

			this.headers 				= JSON.parse(this.$route.query.headers)
			this.sortedBy 				= JSON.parse(this.$route.query.sort)
			this.selected.status 		= JSON.parse(this.$route.query.status)
			this.selected.modalities 	= JSON.parse(this.$route.query.modalities)
			this.selected.date_init 	= this.$route.query.date_init
			this.selected.date_end 		= this.$route.query.date_end
			this.selected.mode 			= this.$route.query.mode
			this.selected.account_id 	= this.requester_selected_id
			this.search = this.$route.query.search || ''

			this.organization_logo 		= this.instance_config ? this.instance_config.logo_url : ''

			await this.GetSchedulingsToReport()
		}
	}
</script>

<style lang="css" scoped>
	#report-print {
		font-size: 2rem;
		color: black;
	}

	table 
	{
		page-break-after: auto;
	}

	table thead 
	{
		display: table-header-group;
	}

	table tfoot 
	{
		display: table-footer-group;
	}
</style>