<template>
	<div class="col-12 px-0 d-flex flex-wrap align-items-start">
        <div class="col-6">
            <div class="col px-0 mr-0 mb-4 d-flex flex-nowrap align-items-center justify-content-start">
                <div class="pl-0 mr-2 col-auto">
                    <div
                        class="font-32"
                        :class="{
                            'text-primary':         invoice_scheduling,
                            'text-secondary':       !invoice_scheduling
                        }"
                        @click="ToggleInvoiceScheduling"
                    >
                        <i
                            class="fad"
                            :class="{
                                'fa-toggle-on':     invoice_scheduling,
                                'fa-toggle-off':    !invoice_scheduling
                            }"
                        ></i>
                    </div>
                </div>
                <label
                    class="mb-0"
                    @click="ToggleInvoiceScheduling"
                >
                    Faturamento no Agendamento
                </label>
            </div>
            <div class="col-12 px-0">
                <p class="text-start">
                    Habilite esta opção para realizar o faturamento dos procedimentos no momento do agendamento quando for pronto atendimento ou encaixe, e na confirmação quando for pré-agendamento.
                </p>
            </div>
        </div>
        <div class="col-6">
            <div class="col px-0 mr-0 mb-4 d-flex flex-nowrap align-items-center justify-content-start">
                <div class="pl-0 mr-2 col-auto">
                    <div
                        class="font-32"
                        :class="{
                            'text-primary':         indicator_doctor,
                            'text-secondary':       !indicator_doctor
                        }"
                        @click="ToggleIndicatorDoctor"
                    >
                        <i
                            class="fad"
                            :class="{
                                'fa-toggle-on':     indicator_doctor,
                                'fa-toggle-off':    !indicator_doctor
                            }"
                        ></i>
                    </div>
                </div>
                <label
                    class="mb-0"
                    @click="ToggleIndicatorDoctor"
                >
                    Solicitante Obrigatório
                </label>
            </div>
            <div class="col-12 px-0">
                <p class="text-start">
                    Habilite esta opção para que o solicitante seja obrigatório.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'

export default {
    name: 'Options',
    props: {
        organization: 	{
            type: 		Object,
            default: 	() => {},
            required: 	true
        }
    },
    data() {
        return {
            invoice_scheduling: false,
            indicator_doctor: false
        }
    },
    watch: {
        organization: {
            handler(val) {
                if(!_.isEmpty(val)) {
                    this.invoice_scheduling = val?.instances[0]?.config?.invoice_scheduling ?? false,
                    this.indicator_doctor = val?.instances[0]?.config?.indicator_doctor ?? false
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        ToggleInvoiceScheduling() {
            this.StartLoading();

            return window.api.call('post', '/toggle-invoice-scheduling', {
                account_id: this.organization.id
            })
                .then(({data}) => {
                    if(!data.response) {
                        Swal.fire({
                            title: 'Erro ao alterar o faturamento do agendamento',
                            icon: 'error',
                            position: 'top',
                            toast: true,
                            showConfirmButton: 	true,
                        });

                        return
                    }

                    this.invoice_scheduling = !this.invoice_scheduling

                    Swal.fire({
                        title: `Faturamento no agendamento ${ this.invoice_scheduling ? 'habilitado' : 'desabilitado' }`,
                        icon: 'success',
                        position: 'top',
                        toast: true,
                        timer: 4000,
                        showConfirmButton: 	false,
                        timerProgressBar: 	true,
                    });
                })
                .finally(() => {
                    this.FinishLoading();
                })
        },
        ToggleIndicatorDoctor() {
            this.StartLoading();

            return window.api.call('post', '/toggle-indicator-doctor', {
                account_id: this.organization.id
            })
                .then(({data}) => {
                    if(!data.response) {
                        Swal.fire({
                            title: 'Erro ao alterar o solicitante obrigatório',
                            icon: 'error',
                            position: 'top',
                            toast: true,
                            showConfirmButton: true
                        })

                        return
                    }

                    this.indicator_doctor = !this.indicator_doctor

                    Swal.fire({
                        title: `Solicitante obrigatório ${this.indicator_doctor ? 'habilitado' : 'desabilitado'}`,
                        icon: 'success',
                        position: 'top',
                        toast: true,
                        timer: 4000,
                        showConfirmButton: false,
                        timerProgressBar: true
                    })
                })
                .finally(() => {
                    this.FinishLoading()
                })

        }
    }
}
</script>

<style lang="css" scoped>

</style>