<template>
	<div class="container-large"
		 :class="!printStatement ? 'pb-4' : 'pb-1'">
		<transition name="fade" mode="out-in">
			<div
				v-if="step === 'pre'"
				:key="step"
			>

			</div>
			<div
				v-if="step === 'module'"
				:key="step"
				class="pb-3"
			>
				<Modules
					:label="'Agendamento'"
					type="strict"
					:module_id="scheduling.module_id"
					@next="WalkStep"
				/>
			</div>
			<div
				v-if="step === 'modality'"
				:key="step"
				class="pb-3"
			>
				<Modalities
					:label="'Agendamento'"
					type="strict"
					:module_id="scheduling.module_id"
					:modality_id="scheduling.modality_id"
					@next="WalkStep"
					@previous="step = 'module'"
				/>
			</div>
			<div
				v-if="step === 'proceeding-and-partnership'"
				:key="step"
				class="pb-3"
			>
				<ProceedingAndPartnership
					:label="'Agendamento'"
					:modality_id="scheduling.modality_id"
					:partnership="scheduling.partnership"
					:proceedings="scheduling.proceedings"
					@next="WalkStep"
					@previous="step = 'modality'"
				/>
			</div>
			<div
				v-if="step === 'schedules'"
				:key="step"
				class="pb-3"
			>
				<Schedules
					:label="'Agendamento'"
					type="scheduling"
					:modality_id="scheduling.modality_id"
					:proceedings="scheduling.proceedings"
					:schedules="scheduling.schedules"
					@next="WalkStep"
					@previous="step = 'proceeding-and-partnership'"
				/>
			</div>
			<div
				v-if="step === 'patient'"
				:key="step"
				class="pb-3"
			>
				<Patient
					:label="'Agendamento'"
					:patient="scheduling.patient"
					@next="WalkStep"
					@previous="step = 'schedules'"
				/>
			</div>
			<div
				v-if="step === 'authorization-and-guide'"
				:key="step"
				class="pb-3"
			>
				<AuthorizationAndGuide
					:label="'Agendamento'"
					:classification="scheduling.classification"
					:authorization="scheduling.authorization"
					:guide="scheduling.guide"
					@next="WalkStep"
					@previous="step = 'patient'"
				/>
			</div>
			<div
				v-if="step === 'confirm'"
				:key="step"
				:class="!printStatement ? 'pb-4' : 'pb-1'"
			>
				<Confirm
					:label="'Agendamento'"
					type="scheduling"
					:module_id="scheduling.module_id"
					:modality_id="scheduling.modality_id"
					:partnership="scheduling.partnership"
					:proceedings="scheduling.proceedings"
					:time="scheduling.time"
					:patient="scheduling.patient"
					:classification="scheduling.classification"
					:authorization="scheduling.authorization"
					:guide="scheduling.guide"
					:place="scheduling.place"
					:printStatement="printStatement"
					:journey_id="scheduling.journey_id"
					:indicator_doctor="scheduling.indicator_doctor"
					@indicator-doctor="SetIndicatorDoctor"
					ref="confirm"
					@next="WalkStep"
					@previous="DynamicPrevious"
				/>
			</div>
		</transition>
	</div>
</template>

<script>
	import Modules from './components/Modules'
	import Modalities from './components/Modalities'
	import ProceedingAndPartnership from './components/ProceedingAndPartnership'
	import Schedules from './components/Schedules'
	import Patient from './components/Patient'
	import AuthorizationAndGuide from './components/AuthorizationAndGuide'
	import Confirm from './components/Confirm'

	import _ from 'lodash'
	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'SchedulingWizard',
		components: 	{
			Modules,
			Modalities,
			ProceedingAndPartnership,
			Schedules,
			Patient,
			AuthorizationAndGuide,
			Confirm
		},
		data () {
			return {
				step: 			'pre',
				printStatement: false,
				places: 		[],
				scheduling: 	{
					module_id: 			null,
					modality_id: 		null,
					journey_id:			null,
					schedules: 			[],
					partnership: 		null,
					proceedings: 		[],
					time: 				null,
					patient: 			null,
					place: 				null,
					classification: 	1,
					authorization: 		'',
					guide: 				'',
					obs: 				'',
					indicator_doctor:	null,
					payment_info:		null,
				},
				is_flow: false,
				el_laudo: null,
				skips: {
					'pre': false,
					'module': false,
					'modality': false,
					'proceeding-and-partnership': false,
					'schedules': false,
					'patient': false,
					'authorization-and-guide': false,
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id'
			])
		},
		watch: 	{
			step: {
				handler(val)
				{
					let hash 	= ''

					switch(val)
					{
						case 'module':
							hash = '#modulo'
						break
						case 'modality':
							hash = '#modalidade'
						break
						case 'proceeding-and-partnership':
							hash = '#convenio-e-procedimentos'
						break
						case 'schedules':
							hash = '#horarios'
						break
						case 'patient':
							hash = '#paciente'
						break
						case 'authorization-and-guide':
							hash = '#classificacao-e-autorizacao'
						break
						case 'confirm':
							hash = '#confirmacao'
						break
					}

					window.location.hash 	= hash
				},
				immediate: true
			},
			$route(to)
			{
				switch(to.hash)
				{
					case '#modulo':
						this.step = 'module'
					break
					case '#modalidade':
						this.step = 'modality'
					break
					case '#convenio-e-procedimentos':
						this.step = 'proceeding-and-partnership'
					break
					case '#horarios':
						this.step = 'schedules'
					break
					case '#paciente':
						this.step = 'patient'
					break
					case '#classificacao-e-autorizacao':
						this.step = 'authorization-and-guide'
					break
					case '#confirmacao':
						this.step = 'confirm'
					break
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading', 'GetModulesAndModalities'
			]),
			CheckSkip(step) {
				let is_skip = this.skips[step];

				if(!is_skip) return;
				
				let param = null;

				switch(step) {
					case 'module':
						param = {
							id: this.scheduling.module_id
						}
					break;
					case 'modality':
						param = {
							id: this.scheduling.modality_id
						}
					break;
					case 'partnership_proceedings': 

					break;
					case 'schedule': 
						this.step = 'patient'
					break;
					case 'patient': 
						param = this.scheduling.patient
					break;
					case 'authorization-and-guide': 
						this.step = 'confirm'
					break;
				}

				this.WalkStep(param)
			},
			async WalkStep(param = null) {
				switch(this.step) {
					case 'pre':
						if(!this.skips.pre) {
							this.scheduling.module_id 		= null
							this.scheduling.modality_id 	= null
							this.scheduling.partnership 	= null
							this.scheduling.proceedings 	= []
							this.scheduling.schedules 		= []
							this.scheduling.time 			= null
							this.scheduling.patient 		= null
							this.scheduling.classification 	= 1
							this.scheduling.places 		 	= null
							this.scheduling.authorization	= ''
							this.scheduling.guide 			= ''
							this.scheduling.obs 			= ''
							this.scheduling.journey_id		= null
							this.scheduling.indicator_doctor = null
							this.scheduling.payment_info 	= null
						}

						this.step 						= 'module'
					break
					case 'module':
						this.scheduling.module_id 		= param.id

						this.step 						= 'modality'
					break
					case 'modality':
						this.scheduling.modality_id 	= param.id

						this.step 						= 'proceeding-and-partnership'
					break
					case 'proceeding-and-partnership':
						this.scheduling.proceedings 	= param.proceedings
						this.scheduling.schedules 		= _.orderBy(param.schedules, 'nome')
						this.scheduling.partnership 	= param.partnership

						this.step 						= 'schedules'
					break
					case 'schedules':
						this.scheduling.time 			= param

						this.step 						= 'patient'
					break
					case 'patient':
						this.scheduling.patient 		= param

						if(this.scheduling.time.schedule.continua) {
							this.step = 'authorization-and-guide'
						} else {
							this.step = 'confirm'
						}
					break
					case 'authorization-and-guide':
						this.scheduling.authorization	= param.authorization
						this.scheduling.classification 	= param.classification
						this.scheduling.guide 			= param.guide

						this.step 						= 'confirm'
					break
					case 'confirm':
						this.scheduling.obs 					= param.obs
						this.scheduling.place 					= param.place

						this.scheduling.payment_info			= param.payment_methods_info

						await this.SaveScheduling()
					break
				}

				this.CheckSkip(this.step)
			},
			DynamicPrevious()
			{
				if(this.scheduling.time.schedule.continua)
				{
					this.step = 'authorization-and-guide'

				}else{

					this.step = 'patient'
				}
			},
			SaveScheduling() {
				const vm 	= this

				this.StartLoading()

				return window.api.call('post', '/save-scheduling', {
						...this.scheduling,
						account_id: 	this.requester_selected_id
					})
					.then(({data}) => {
						if(data.response) {
							vm.scheduling.journey_id = data.journey
							vm.printStatement = true

							Swal.fire({
								icon: 				'question',
								title: 				'Deseja imprimir o dados do agendamento ?',
								showConfirmButton: 	true,
								showCancelButton: 	true,
								confirmButtonText: 	'Sim',
								cancelButtonText: 	'Não',
								customClass: 		{
									confirmButton: 		'success',
								},
							})
							.then(async (res) => {
								if(res.isConfirmed) {
									vm.PrintTimeline()
								}

								vm.printStatement = false

								if(!this.is_flow) {
									vm.step = 'pre'
	
									vm.WalkStep()
								}

								Swal.fire({
									title: 	'Concluido !',
									html: 	`Paciente agendado com sucesso.<br>ID: ${data.journey}`,
									icon: 	'success'
								})
								.then(() => {
									if(this.is_flow) {
										window.location.href = `${process.env.VUE_APP_APP_URL}/laudar/finalizar`
									}
								})
							})
						} else {
							Swal.fire({
								icon: 	'error',
								title: 	'Opss...',
								text: 	'Houve um erro ao concluir, contate o suporte.'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			PrintTimeline()
			{
				const vm  = this
				
				const fn  = (event) => {
					if (event.data.print)
					{
						window.removeEventListener('message', fn)

						win.close()
					}
				}

				const win     	= window.open(`/pesquisa/${vm.scheduling.journey_id}/imprimir`, '_blank')
				win.mainWindow  = window
				window.addEventListener('message', fn)
			},
			SetIndicatorDoctor(doctor) {
				this.scheduling.indicator_doctor = doctor
			},
			GetFlowData() {
				return window.api.call('post', '/get-flow-data')
					.then(({data}) => {
						if(data.response) {
							this.el_laudo = data.el_laudo

							this.scheduling.module_id = this.el_laudo.flowData.idModule
							this.scheduling.modality_id = this.el_laudo.flowData.idModality
							this.scheduling.patient = this.el_laudo.step_1.paciente
							this.scheduling.indicator_doctor = this.el_laudo.indicator

							this.skips['pre'] = true
							this.skips['module'] = true
							this.skips['modality'] = true
							this.skips['patient'] = true
						} else {
							Swal.fire({
								icon: 'error',
								title: 'Erro!',
								text: data.message ?? 'Ocorreu um erro, contate o suporte!',
							})
						}
					})
			}
		},
		async beforeMount() {
			this.is_flow = this.$route?.query?.flow ?? false

			if(this.is_flow) {
				await this.GetFlowData()
			}

			await this.GetModulesAndModalities()

			this.WalkStep()
		}
	}
</script>

<style lang="css" scoped>
</style>