<template>
	<div>
		<div class="row">
			<div class="col-12">
				<div class="table-responsive" v-if="headers.length > 0"
						:style="{
							...style_table
						}"
						@scroll.capture="ScrollEvent"
						ref=table>
					<table class="table table-custom font-14">
						<thead
							:class="{
								'fixed-header': fixedHeader
							}"
							:style="{
								...style_header
							}">
							<tr>
								<th v-if="checkbox">
									<div class="form-check">
										<input 
											type="checkbox" 
											id="checkbox-all"
											v-model="check_all"
										>
										<label for="checkbox-all"></label>
									</div>
								</th>
								<th 
									v-for="(header,index) in headers" 
									:key="index"
									class="text-muted"
									:class="{
										'text-center': 	header.align == 'center',
										'text-left': 	header.align == 'left',
										'text-right': 	header.align == 'right',
										'sortable': 	header.value && header.sortable,
										'active': 		sortedBy.column == header.value,
										'pb-1':			!fixedHeader,
										'pb-3':			fixedHeader,
									}"
									@click="SortData(header)"
								>
									<div class="th-content">
										{{header.text}} 
										<div class="sort-icon">
											<i :class="`far ${sortedBy.icon}`" v-if="sortedBy.column == header.value"/>
										</div>
									</div>
								</th>
							</tr>
						</thead>
						<transition 
							name="fast-fade" 
							mode="out-in"
						>
							<tbody v-if="filtered_items.length > 0">
								<tr 
									v-for="(item,index) in filtered_items"
									:class="`${ trButton ? 'tr-btn' : '' } ${row_color ? item.row_status_color ? item.row_status_color : item.row_color : ''}`"	
									:key="index * Math.random()"
									@click="ByClickOnTR(item)"
								>
									<td v-if="checkbox" scope="col">
										<div class="form-check">
											<input 
												type="checkbox" 
												:id="`checkbox-${index}`"
												:value="item[checkbox_value]"
												v-model="selected_checkboxes"
												@change="EmitCheckboxes"
											>
											<label :for="`checkbox-${index}`"></label>
										</div>
									</td>
									<slot name="body" :item="item" :index="index"></slot>
								</tr>
							</tbody>
							<tbody 
								v-else-if="filtered_items.length < 1 && items.length > 0"
								key="empty"
							>
								<tr>
									<td :colspan="headers.length">
										<slot name="no-match" >
											<div class="custom-alert-warning">	
												<b>Sua pesquisa por "{{ search }}" retornou sem resultados.</b>
											</div>
										</slot>
									</td>
								</tr>
							</tbody>
							<tbody 
								v-else
								key="no-data"
							>
								<tr>
									<td :colspan="checkbox ? headers.length + 1 : headers.length">
										<slot name="no-data">
											<div class="custom-alert-info">						
												<b>Não há registros para exibir.</b>
											</div>
										</slot>
									</td>
								</tr>
							</tbody>
						</transition>
						<tfoot v-if="items.length > 0">
							<tr>
								<slot name="footer" :items="items"></slot>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
			<div class="col-12 text-center text-md-right mt-3 align-self-center counter" v-if="(filtered_items.length ? filtered_items.length : items.length) > 0 && showTotal" 
				:style="{
					...style_footer
				}">
				<span>
					{{paginate ? 
						`De ${paginateSetup.init_item} até ${paginateSetup.end_item} de ${filtered_items.length ? filtered_items.length : items.length} itens` : 
						`Exibidos: ${filtered_items.length ? filtered_items.length : items.length} ${total ? 'de ' + numberWithDots(total) : '' } itens`}}
				</span>
			</div>
			<div class="col-12 col-md text-center text-md-right mt-3 align-self-center" v-if="paginate">
				<div class="btn-group btn-group-sm">
					<button
						type="button"
						class="btn btn-primary"
						:disabled="paginateSetup.page === 1"
						@click="WalkPaginate('prev')"
					>
						Anterior
					</button>
					<button 
						type="button"
						class="btn btn-primary"
						disabled
					>
						{{ paginateSetup.page }}
					</button>
					<button
						type="button"
						class="btn btn-primary"
						:disabled="paginateSetup.page === paginateSetup.total_pages || filtered_items.length < 1"
						@click="WalkPaginate('next')"
					>
						Próximo
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Helpers from '../../helpers'

	const _ = require('lodash')
	
	const helpers 		= new Helpers()

	import moment from 'moment'

	export default {

		name: 'DataTables',
		props: {
			type:			{
				type:			String,
				default:		''
			},
			value: 			{
				type: 			Array,
				required: 		false,
				default: 		() => []
			},
			headers: 		{
				type: 			Array,
				required: 		true,
				default: 		() => []
			},
			search: 		{
				type: 			String,
				required: 		false,
				default: 		''
			},
			paginate: 		{
				type: 			Boolean,
				required: 		false,
				default: 		false
			},
			itemsPerPage: 	{
				type: 			Number,
				required: 		false,
				default: 		10
			},
			trButton: 		{
				type: 			Boolean,
				required: 		false,
				default: 		false
			},
			checkbox: 		{
				type: 			Boolean,
				default: 		false
			},
			row_color: 		{
				type: 			Boolean,
				default: 		false
			},
			checkbox_value: {
				type: 			String,
				default: 		'id'
			},
			height:			{
				type:			String,
				required:		false,
				default:		null
			},
			max_height: 	{
				type:			String,
				required:		false,
				default:		null
			},
			min_height: 	{
				type:			String,
				required:		false,
				default:		null
			},
			scrollUpdate:	{
				type:			Boolean,
				default:		false
			},
			offset:			{
				type:			[String, Number],
				default:		''
			},
			prop_sortedBy:	{
				type:			Object,
				default:		() => {}
			},
			fetchSort:		{
				type:			Boolean,
				default:		false
			},
			returnFetchSort:{
				type:			Boolean,
				default:		true
			},
			total: {
				type:			Number,
				default:		null
			},
			showTotal:		{
				type:			Boolean,
				default:		true
			},
			fixedHeader:	{
				type:			Boolean,
				default: 		false	
			},
			header_style:	{
				type:			Object,
				required:		false,
				default:		() => {}
			},
			footer_style:	{
				type:			Object,
				required:		false,
				default:		() => {}
			}
		},
		data () {
			return {
				currentPage: 			1,
				items: 					[],
				items_limiteds: 		[],
				sortedBy: 				{
					column: 				'',
					direction: 				'',
					icon: 					''
				},
				paginateSetup: 			{
				  	page: 					null,
				  	per_page: 				null,
				  	pre_page: 				null,
				  	next_page: 				null,
				  	total: 					null,
				  	total_pages: 			null,
				  	data: 				[]
				},
				selected_checkboxes: 	[],
				check_all: 				false,
			}
		},
		computed: {
			style_table() {
				const vm = this

				let obj = {}

				if(vm.height) obj.height = vm.height

				if(vm.max_height) obj['max-height'] = vm.max_height
				
				if(vm.min_height) obj['min-height'] = vm.min_height
				
				return obj
			},
			style_header() {
				const vm = this

				return vm.header_style ?? {}
			},
			style_footer() {
				const vm = this

				return vm.footer_style ?? {}
			},
			filtered_items() {
				//BUSCA
				let items = this.items.filter( item => {
					let search = helpers.SanitizedDocument(helpers.RemoveAcento(this.search)).toString().toLowerCase()
					let match = false

					this.headers.map(header => {
						if(header.value && window._.get(item, header.value)) {
							let i = helpers.SanitizedDocument(helpers.RemoveAcento(window._.get(item, header.value))).toString().toLowerCase()

							if(i.indexOf(search) > -1) {
								match 		= true
							}
						}
					})

					return this.search && this.headers.length > 0 ? match : true
				})

				//ORDENAÇÃO
				if(!this.fetchSort) {
					this.headers.map(header => {
						if(header.value == this.sortedBy.column) {
							items.sort(this.OrderItems(header.value, this.sortedBy.direction))
						}
					})
				}

				//PAGINATION
				if(this.paginate) {
					let itemsPerPage = this.itemsPerPage ? this.itemsPerPage : 5

					let numberOfPages = Math.ceil(items.length / itemsPerPage)

					let paginate = this.Paginate(items, this.currentPage, itemsPerPage)

					items = paginate.data
					this.paginateSetup = paginate
				}

				return items
			}
		},
		watch: {
			prop_sortedBy: {
				immediate: true,
				handler(val) {
					if(val) {
						this.sortedBy = { ...val }
					}
				}
			},
			value: {
				immediate: true,
				handler(val)
				{
					this.items 					= val
					this.selected_checkboxes 	= []
					this.check_all 				= false
				},
				deep: true
			},
			check_all: {
				handler(val)
				{
					if(val)
					{
						this.items.forEach( item => {
							this.selected_checkboxes.push(item[this.checkbox_value])
						})

					}else{

						this.selected_checkboxes 		= []

					}

					this.EmitCheckboxes()
				}
			}
		},
		methods: {
			EmitCheckboxes()
			{
				this.$emit('checkeds', this.selected_checkboxes)
			},
			ByClickOnTR(item)
			{
				if(this.trButton)
				{
					this.$emit('by-click-on-tr', item)
				}
			},
			OrderItems(key, order = 'ASC') 
			{
				return function InnerSort(a, b) 
				{
					if(typeof window._.get(a, key) === 'boolean' || typeof window._.get(b, key) === 'boolean')
					{
						if(window._.get(a, key) && !window._.get(b, key))
						{
							return (order === 'DESC') ? 1 : -1

						}else if(!window._.get(a, key) && window._.get(b, key))
						{
							return (order === 'DESC') ? -1 : 1
						}

						return 0
					}

					if (!window._.get(a, key) || !window._.get(b, key)) 
					{
						return 0
					}

					const varA 		= (typeof window._.get(a, key) === 'string') ? window._.get(a, key).toUpperCase() : window._.get(a, key)
					const varB 		= (typeof window._.get(b, key) === 'string') ? window._.get(b, key).toUpperCase() : window._.get(b, key)

					let comparison 	= 0

					const dateA = moment(varA, 'DD/MM/YYYY')
					const dateB = moment(varB, 'DD/MM/YYYY')

					if(dateA._isValid && dateB._isValid) {
						comparison			= dateA > dateB ? 1 : -1
					} else {
						if (varA > varB) {
							comparison 		= 1
						} else if (varA < varB) {
							comparison 		= -1
						}						
					}

					return (
						(order === 'DESC') ? (comparison * -1) : comparison
					)
				}
			},
			Paginate( items, page = 1, per_page = 5) 
			{
			    page 				= page || 1
				per_page 			= per_page || 5
			  	let offset 			= (page - 1) * per_page
				let paginatedItems 	= items.slice(offset).slice(0, per_page)
				let total_pages 	= Math.ceil(items.length / per_page)

				return {
				  	page: 			page,
				  	per_page: 		per_page,
				  	init_item: 		(offset + 1),
				  	end_item: 		(offset + paginatedItems.length),
				  	pre_page: 		page - 1 ? page - 1 : null,
				  	next_page: 		(total_pages > page) ? page + 1 : null,
				  	total: 			items.length,
				  	total_pages: 	total_pages,
				  	data: 			paginatedItems
			  	}
			},
			WalkPaginate(mode)
			{
				switch(mode)
				{
					case 'prev':
						this.currentPage 	= this.currentPage <= 1 ? 1 : this.currentPage - 1
					break;
					case 'next':
						this.currentPage 	= this.currentPage < this.paginateSetup.total_pages ? this.currentPage + 1 : this.currentPage - this.paginateSetup.total_pages
					break;
				}
			},
			SortData(header)
			{
				if(header.value && header.sortable)
				{
					if(this.sortedBy.column != header.value)
					{
						this.sortedBy.column 	= header.value
						this.sortedBy.direction = ''
						this.sortedBy.icon 		= ''
					}

					let items 		= [...this.items]

					switch(this.sortedBy.direction)
					{
						case 'ASC':
							this.sortedBy.direction 	= 'DESC'
							this.sortedBy.icon 			= 'fa-chevron-down'

							items.sort(this.OrderItems(header.value, this.sortedBy.direction))
						break;
						case 'DESC':
							this.sortedBy.column 		= ''
							this.sortedBy.direction 	= ''
							this.sortedBy.icon 			= ''

							items.sort(this.OrderItems(this.headers[0].value, this.sortedBy.direction))
						break;
						default: 
							this.sortedBy.direction 	= 'ASC'
							this.sortedBy.icon 			= 'fa-chevron-up'

							items.sort(this.OrderItems(header.value, this.sortedBy.direction))
						break;
					}

					if(this.fetchSort) {
						this.$emit('fetch', { ...this.sortedBy })

						if(this.returnFetchSort) {
							return
						}
					}

					this.items = [ ...items ]
				}
			},
			ScrollEvent(event) {
				if(this.scrollUpdate) {
					const vm = this
					const table = this.$refs.table
					
					let bottomTable = (table.offsetHeight + Math.ceil(table.scrollTop)) >= (table.scrollHeight)
					let newOffset = vm.offset + 200

					if (bottomTable) {
						vm.$emit('load-table', {
							offset: newOffset
						})
					}
				}
			},
			numberWithDots(x) {
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
			}
		}
	}
</script>

<style lang="css" scoped>
	.table-responsive {
		min-height: 150px;
	}
	table.table-custom .th-content
	{
		display: flex;
		flex-direction: row;
	}

	table.table-custom .th-content .sort-icon
	{
		margin: 0 5px;
	}

	table.table-custom .header-sticky
	{
		position: sticky;
	}

	/* thead tr, thead tr th {
		height: inherit;
	} */

	/* thead tr th {
		display: flex;
		align-items: center;
		justify-content: center;
	} */
</style>