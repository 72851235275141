<template>
	<div class="pb-4 pb-md-6">
		<div class="container">
			<div class="row justify-content-center">

				<div class="col-12">
					<div class="d-block mx-auto pb-2 pb-md-3 text-md-center max-500 pr-6 pr-md-0">
						<span>Agendas</span>
						<h4 class="font-24 font-md-32 m-0">Configurar Agenda</h4>
					</div>
				</div>
				<div class="col-12 col-md-9" v-if="schedule && (schedule.future_scheduling || schedule.future_blocked_blocks)">
					<div class="alert alert-warning">
						<i class="fal fa-exclamation-triangle mr-3"></i>
						Quaisquer alterações definidas nesta agenda serão efetuadas em dias que não há agendamentos ou bloqueios.
					</div>
				</div>
				<div class="col-12 col-md-9">

					<div class="col-12 px-0 scroll-x scroll-transparent d-flex">
						<ul class="col-12 px-0 nav nav-pills nav-fill nav-theme w-md-100 justify-content-md-center my-1 mb-3 my-md-2 mb-md-4 px-5 px-md-0">
							<li class="nav-item">
								<a class="nav-link pb-md-4 px-md-4 active" data-toggle="tab" href="#dados">Dados Básicos</a>
							</li>
							<li class="nav-item" v-if="!basic_data.continuous_schedule">
								<a class="nav-link pb-md-4 px-md-4" data-toggle="tab" href="#blocos">Blocos</a>
							</li>
							<li class="nav-item">
								<a class="nav-link pb-md-4 px-md-4" data-toggle="tab" href="#periodicidade">Periodicidade</a>
							</li>
						</ul>
					</div>

				</div>
			</div>

			<div class="tab-content">
				<div id="dados" class="tab-pane active">
					<BasicData
						type="config"
						ref="basic_data"
						:form="basic_data"
						:is_started="is_started"
						@append="AppendToSchedule"
					/>
				</div>
				<div id="blocos" class="tab-pane" v-if="!basic_data.continuous_schedule">
					<div class="row">
						<div class="col-12">
							<TimeRange
								ref="time_range"
								type="config"
								:time_obj="{
									...time_range,
								}"
								:time_init="time_range.time_init"
								:time_end="time_range.time_end"
								:is_started="is_started"
								:is_vacancy="is_vacancy"
								:attendances_obj="{
									attendances: basic_data.attendances,
									max_attendances: basic_data.max_attendances
								}"
								@time-init="time_range.time_init = $event"
								@time-end="time_range.time_end = $event"
								@total-time="time_range.total_time = $event"
								@max-attendances="basic_data.max_attendances = $event"
								@attendances="basic_data.attendances = $event"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<BlockConfig
								ref="block_config"
								type="config"
								:time_obj="time_range"
								:props_blocks="block_config.blocks"
								:map="block_config.map"
								:is_started="is_started"
								:is_vacancy="is_vacancy"
								@append="AppendToSchedule"
								@pause-time="PauseTimeChangeHandler"
								@has_unselected_blocks="has_unselected_blocks = $event"
							/>
						</div>
					</div>
				</div>
				<div id="periodicidade" class="tab-pane">
					<PeriodicityAndDates
						type="config"
						ref="periodicity"
						:continuous_schedule="basic_data.continuous_schedule"
						:date_init="periodicity.date_init"
						:date_end="periodicity.date_end"
						:temporary_schedule="periodicity.temporary_schedule"
						:cyclic_schedule="periodicity.cyclic_schedule"
						:dates="periodicity.dates"
						:is_started="is_started"
						@append="AppendToSchedule"
					/>
				</div>
			</div>
			<div class="row justify-content-center mt-4">
				<div class="col-md-9">
					<div class="d-flex justify-content-between">
						<template v-if="schedule">
							<router-link 
								class="btn btn-light px-5"
								:to="{ name: 'Schedules', params: {
										module_id: 		schedule.modality.module_id,
										modality_id: 	schedule.modality.id
									}
								}"
							>
								voltar
							</router-link>
						</template>
						<button
							type="button"
							class="btn btn-primary px-5"
							@click="UpdateSchedule"
						>
							salvar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'

	import BasicData from './components/BasicData'
	import PeriodicityAndDates from './components/PeriodicityAndDates'
	import TimeRange from './components/TimeRange'
	import BlockConfig from './components/BlockConfig'
	export default {

		name: 'ScheduleConfig',
		props: 	{
			schedule_id: 	{
				type: 			[String, Number],
				default: 		null,
				required: 		true
			}
		},
		components: 	{
			BasicData,
			PeriodicityAndDates,
			TimeRange,
			BlockConfig
		},
		data () {
			return {
				schedule: 		null,
				is_started:		true,
				is_vacancy:		false,
				basic_data: 	{
					name: 					null,
					tolerance: 				0,
					time_attendance:		0,
					panel_text: 			null,
					auto_scheduling: 		false,
					continuous_schedule: 	false,
					attendances:	0,
					max_attendances:0
				},
				time_range: 	{
					time_init: 		null,
					time_end: 		null,
					total_time: 	null,
				},
				block_config: 	{
					new_map: 		null,
					map: 			null,
					blocks:			[]
				},
				periodicity: 	{
					date_init: 			new Date().toISOString().substr(0, 10),
					date_end: 			new Date().toISOString().substr(0, 10),
					temporary_schedule: false,
					cyclic_schedule: 	true,
					dates: 				[],
					week:				[]
				},
				pause_time:			null,
				has_unselected_blocks: false,
			}
		},
		computed: 	{
			...mapGetters('auth', [
				'requester_selected_id', 'requesters'
			])
		},
		watch: {
			'basic_data.attendances': {
				async handler(val) {
					if(!this.is_started) {
						await this.PrepareMappedBlocksToSave()
					}
				}
			},
			'block_config.new_map': {
				async handler(val) {
					if(!this.is_started) {
						this.block_config.map = val

						if(val && val.length)  {
							await this.PrepareMappedBlocksToSave()
						}
					}
				},
				deep: true,
			},
		},
		methods: 	{
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetScheduleForConfig()
			{
				const vm 		= this
				vm.StartLoading()

				return window.api.call('post', '/get-schedule-for-config', {
						schedule_id: 		vm.schedule_id,
						account_id: 		vm.requester_selected_id
					})
					.then(({data}) => {
						if(data.response)
						{
							vm.is_vacancy 						= data.is_vacancy
							vm.schedule 						= data.schedule

							vm.basic_data.name 					= vm.schedule.nome
							vm.basic_data.tolerance 			= vm.schedule.tolerancia
							vm.basic_data.time_attendance		= vm.schedule.time_attendance
							vm.basic_data.panel_text 			= vm.schedule.texto
							vm.basic_data.auto_scheduling 		= vm.schedule.auto_agendamento
							vm.basic_data.continuous_schedule 	= vm.schedule.continua
							vm.basic_data.is_mobilemed 			= vm.schedule.is_mobilemed

							vm.basic_data.attendances 			= vm.schedule.attendances ?? 0

							if(!vm.basic_data.continuous_schedule)
							{
								vm.time_range.time_init 			= vm.schedule.horario_inicio.substring(0, 5)
								vm.time_range.time_end 				= vm.schedule.horario_fim.substring(0, 5)

								vm.block_config.map 				= vm.schedule.mapa_blocos ? JSON.parse(vm.schedule.mapa_blocos) : null
								vm.block_config.new_map 			= vm.block_config.map

								vm.periodicity.cyclic_schedule 		= vm.schedule.ciclica
								vm.periodicity.dates 				= JSON.parse(vm.schedule.dias_habilitados)
								vm.periodicity.week 				= JSON.parse(vm.schedule.dias_habilitados)
							}

							
							vm.periodicity.date_init 			= vm.schedule.data_inicio
							vm.periodicity.date_end 			= vm.schedule.data_fim
							vm.periodicity.temporary_schedule 	= vm.schedule.temporaria

						}else{

							Swal.fire({
									icon: 		'error',
									title: 		'Eita!',
									text: 		'Agenda não encontrada.'
								})
								.then(() => {

									vm.$router.back()

								})

						}
					})
					.finally(() => {
						vm.is_started = false
						vm.FinishLoading()
					})
			},
			AppendToSchedule(param) {
				switch(param.type) {
					case 'form':
						this.basic_data = param.form
					break
					case 'date_end':
						this.periodicity.date_end = param.date_end
					break
					case 'temporary_schedule':
						this.periodicity.temporary_schedule = param.temporary_schedule
						this.periodicity.date_end = param.date_end
					break
					case 'dates':
						this.periodicity.dates = param.dates
					break
					case 'cyclic':
						this.periodicity.cyclic_schedule = param.cyclic_schedule
					break
					case 'week':
						this.periodicity.week = param.week
					break
					case 'blocks': 
						this.block_config.blocks = param.blocks
					break
					case 'map':
						if(!this.is_started) {
							this.block_config.new_map = param.map || [...param.block_map]
						}
					break
				}
			},
			async UpdateSchedule() {
				const vm 		= this

				if(!vm.basic_data.continuous_schedule && vm.is_vacancy && (!vm.block_config.map || vm.block_config.map.length == 0 || this.has_unselected_blocks))  {
					Swal.fire({
						icon: 'error',
						title: 'Ops...',
						text: 'Em uma agenda por vaga, a configuração de blocos é obrigatória.'
					})

					return
				}

				if(!vm.basic_data.continuous_schedule) {
					if(!(await vm.$refs.basic_data.Next())) return false
	
					if(!(await vm.$refs.time_range.Next())) return false
	
					if(!(await vm.$refs.periodicity.Next())) return false
				}

				vm.StartLoading()
				
				await this.PrepareMappedBlocksToSave()

				let obj 		= {
					...vm.basic_data,
					...vm.time_range,
					...vm.block_config,
					...vm.periodicity,
					modality_id: 	vm.schedule.modality_id,
					account_id: 	vm.requester_selected_id,
					schedule_id: 	vm.schedule.id
				}

				await window.api.call('post', '/update-schedule', obj)
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
									icon: 		'success',
									title: 		'Agenda salva com sucesso.'
								})
								.then(() => {
									vm.$router.push({ name: 'Schedules', params: {
										modality_id: 	vm.schedule.modality_id,
										module_id: 		vm.schedule.modality.module_id
									}})
								})
															
						}else{
							
							Swal.fire({
								icon: 		'error',
								title: 		'Eita!',
								text: 		'Houve um erro ao concluir, contate o suporte.'
							})
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			FormatDate(date) 
			{
				if (!date) return null

				const [year, month, day] = date.split('-')

				return `${day}/${month}/${year}`
			},
			async GetVacancysFromBlockObjects() {
				let attendances = this.basic_data.attendances || 0

				const PAUSE_TYPE = 2

				const blocks = this.block_config.map

				let j = 0;

				let counter = 0

				while(attendances > 0) {
					if(counter > 1000) {
						// console.log('quebrou o looping infinito')
						// break;
					}
					
					counter++

					if(blocks[j].vacancys < blocks[j].limit && blocks[j].type != PAUSE_TYPE) {
						blocks[j].vacancys++;
						attendances--;
					}

					j++;

					if(j == blocks.length) {
						j = 0;
					}
				}

				const MINIMAL_BLOCK_SIZE = 5

				this.block_config.map = blocks.map(block => {
					block.duration_for_vacancys = block.type != PAUSE_TYPE ? block.vacancys * MINIMAL_BLOCK_SIZE : 0

					return block
				})
			},
			async PrepareMappedBlocksToSave() {
				if(!this.block_config.map || !this.block_config.map.length) return;

				this.block_config.map = await this.block_config.map
					.map(block => {
						const MINIMAL_BLOCK_SIZE = 5

						const [ hours, minutes ] = window.helpers.GetHoursAndMinutesFromString(block.first_obj.duration)

						return {
							...block,
							vacancys: 0,
							limit: (parseInt(hours) * 60 + minutes + 5) / MINIMAL_BLOCK_SIZE
						}
					})

				await this.GetVacancysFromBlockObjects()
			},
			PauseTimeChangeHandler(param) {
				this.basic_data.max_attendances = param.max_attendances

				if(this.basic_data.attendances > param.max_attendances) {
					this.basic_data.attendances = param.max_attendances;
				}
			},
		},
		async beforeMount()
		{
			if(this.is_started)
			{
				await this.GetScheduleForConfig()
			}
		}
	}
</script>

<style lang="css" scoped>
</style>