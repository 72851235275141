<template>
	<div class="container-large"
		:class="!printStatement ? 'pb-4 pt-0' : 'pb-1 pt-0'">
		<transition name="fade" mode="out-in">
			<div 
				v-if="step === 'pre'" 
				:key="step"
			>
				
			</div>
			<div 
				v-if="step === 'schedules'"
				:key="step"
				class="pb-3"
			>
				<Schedules 
					:label="'Reagendamento'"
					type="scheduling"
					:modality_id="scheduling.modality_id"
					:proceedings="scheduling.proceedings"
					:schedules="scheduling.schedules"
					@next="WalkStep"
					@previous="DynamicPrevious"
				/>
			</div>
			<div
				v-if="step === 'authorization-and-guide'"
				:key="step"
				class="pb-3"
			>
				<AuthorizationAndGuide
					:label="'Agendamento'"
					:classification="scheduling.classification"
					:authorization="scheduling.authorization"
					:guide="scheduling.guide"
					@next="WalkStep"
					@previous="step = 'patient'"
				/>
			</div>
			<div 
				v-if="step === 'confirm'"
				:key="step"
				:class="!printStatement ? 'pb-3' : 'pb-1'"
			>
				<Confirm 
					:label="'Reagendamento'"
					type="rescheduling"
					:module_id="selected_module_id"
					:modality_id="scheduling.modality_id"
					:partnership="scheduling.partnership"
					:proceedings="proceedings"
					:time="scheduling.time"
					:patient="scheduling.patient"
					:classification="scheduling.classification"
					:authorization="scheduling.authorization"
					:guide="scheduling.guide"
					:printStatement="printStatement"
					:indicator_doctor="scheduling.indicator_doctor"
					ref="confirm"
					@indicator-doctor="SetIndicatorDoctor"
					@next="WalkStep"
					@previous="DynamicPrevious"
				/>
			</div>
		</transition>
	</div>
</template>

<script>
	import Schedules from './components/Schedules'
	import Confirm from './components/Confirm'

	import { is_navigating } from '@/routes.js'

	import _ from 'lodash'

	import Helpers from '../../helpers'
	const helpers 		= new Helpers()

	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'ReschedulingWizard',
		components: {
			Schedules,
			Confirm
		},
		props: {
			source: {
				type:		String,
				default:	null
			}
		},
		data() {
			return {
				source_page:		'',
				step: 				'pre',
				schedulings:		[],
				iter:				0,
				scheduling: 	{
					module_id: 			null,
					modality_id: 		null,
					schedules: 			[],
					proceeding: 		null,
					partnership: 		null,
					time: 				null,
					patient: 			null,
					place: 				null,
					classification: 	1,
					authorization: 		'',
					guide: 				'',
					obs: 				'',
					indicator_doctor:	null,
					payment_info: 		null,
				},
				printStatement:			false,
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			...mapGetters('system', [
				'modules'
			]),
			selected_module_id()
			{
				const module = this.modules.find(m => m.name === this.scheduling.module)

				return module.id
			},
			proceedings() {
				return [ { ...this.scheduling.proceeding } ]
			},
		},
		watch: 	{
			step: {
				handler(val) {
					let hash 	= ''

					switch(val) {
						case 'schedules':
							hash = '#horarios'
						break
						case 'confirm':
							hash = '#confirmacao'
						break
					}

					window.location.hash 	= hash
				},
				immediate: true
			},
			$route(to) {
				switch(to.hash) {
					case '#horarios':
						this.step = 'schedules'
					break
					case '#confirmacao':
						this.step = 'confirm'
					break
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading', 'GetModulesAndModalities'
			]),
			async WalkStep(param = null) {
				switch(this.step) {
					case 'pre':
						await this.GetSchedulingData(this.schedulings[this.iter])

						this.step 						= 'schedules'
					break
					case 'schedules':
						this.scheduling.time 			= param
						this.scheduling.authorization	= ''
						this.scheduling.classification 	= 1
						this.scheduling.guide 			= ''
						this.scheduling.obs 			= ''
						this.scheduling.place 			= null

						this.step 						= 'confirm'
					break
					case 'confirm':
						this.scheduling.obs 			= param.obs
						this.scheduling.place 			= param.place

						this.scheduling.payment_info			= param.payment_methods_info
						
						await this.SaveScheduling()
					break
				}
			},
			DynamicPrevious()
			{
				if(this.step == 'schedules')
				{
					window.history.go(-2)
				}else if(this.step == 'confirm'){

					this.step = 'schedules'
				}
			},
			async GetProceedingsDetails(proceeding_id, partnership_id) {
				this.StartLoading()

				return await window.api.call('post', '/get-proceeding-details',{
						account_id: 		this.requester_selected_id,
						modality_id: 		this.scheduling.modality_id,
						proceeding_id,
						partnership_id
					})
					.then(({data}) => {
						if(data.response) {
							this.scheduling.schedules = _.orderBy(data.schedules, 'nome')
							this.scheduling.proceedings = data.partnership.proceedings
							this.scheduling.proceeding = data.partnership.proceedings[0]
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			async GetSchedulingData(scheduling_id) {
				const vm = this
				
				vm.StartLoading()

				return window.api.call('post', '/get-scheduling-data', {
					account_id: vm.requester_selected_id,
					scheduling: scheduling_id
				})
					.then(async ({data}) => {
						if(data.response) {
							const scheduling = data.scheduling

							vm.scheduling				= {...scheduling}
							vm.scheduling.patient		= scheduling.journey.patient
							vm.scheduling.partnership	= scheduling.partnerships[0]
							
							await vm.GetProceedingsDetails(scheduling.proceeding.id, vm.scheduling.partnership.id)
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			SaveScheduling() {
				const vm 	= this

				vm.StartLoading()
				
				return window.api.call('post', '/save-scheduling', {
						...vm.scheduling,
						proceedings: this.proceedings,
						account_id: 	vm.requester_selected_id
					})
					.then(({data}) => {
						if(data.response) {
							vm.printStatement = true

							Swal.fire({
								icon: 				'question',
								title: 				'Deseja imprimir o dados do agendamento?',
								showConfirmButton: 	true,
								showCancelButton: 	true,
								confirmButtonText: 	'Sim',
								cancelButtonText: 	'Não',
								width: 				'auto',
								customClass: 		{
									confirmButton: 		'success',
								},
							}).then(async (res) => {
								if (res.isConfirmed) {
									vm.PrintTimeline(data.journey)
									
									vm.printStatement = false

									vm.CancelScheduling()

									Swal.fire({	
										title: 	'Concluido !',
										html: 	`Paciente agendado com sucesso.<br>ID: ${data.journey}`,
										icon: 	'success'
									})

									vm.step 		= 'pre'
									vm.iter++

									await vm.WalkStep()
								} else {
									vm.CancelScheduling()

									Swal.fire({
										title: 	'Concluido !',
										html: 	`Paciente agendado com sucesso.<br>ID: ${data.journey}`,
										icon: 	'success'
									})

									vm.step 		= 'pre'
									vm.iter++

									await vm.WalkStep()
								}
						
								if(vm.iter == vm.schedulings.length) {
									if(vm.source_page) {
										await vm.$router.push({ name: vm.source_page })
											.catch((e) => {
												console.error(e)
												vm.$router.push({ name: 'Home' })
											})
									} else {
										await vm.$router.push({ name: 'Home' })
									}
								}
							})
						} else {
							Swal.fire({
								icon: 	'error',
								title: 	'Opss...',
								text: 	'Houve um erro ao concluir, contate o suporte.'
							})
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			CancelScheduling() {
				const vm = this

				vm.StartLoading()

				let arr = []

				arr.push({id: vm.schedulings[vm.iter]})

				return window.api.call('post','/cancel-schedulings', {
							schedulings: arr,
							account_id: vm.requester_selected_id
						})
						.then(({data}) => {
							if(data.response) {
								vm.step		= 'pre'

								return data
							} else {
								window.helpers.errorMessageTop('Houve um erro ao excluir o agendamento anterior, contate o suporte.', 4000)
							}
						})
						.finally(() => {
							vm.dialog 	= false

							vm.FinishLoading()
						})
			},
			PrintTimeline(journey_id) {
				const vm  = this
				
				const fn  = (event) => {
					if (event.data.print) {
						window.removeEventListener('message', fn)

						win.close()
					}
				}

				const win     	= window.open(`/pesquisa/${journey_id}/imprimir`, '_blank')
				win.mainWindow  = window
				window.addEventListener('message', fn)
			},
			SetIndicatorDoctor(doctor) {
				this.scheduling.indicator_doctor = doctor
			}
		},
		async beforeMount() {
			this.source_page = this.$route.params.source
			await this.GetModulesAndModalities()

			this.schedulings = JSON.parse(this.$route.query.schedulings)

			this.WalkStep()
		},
	}
</script>

<style lang="css" scoped>
</style>