<template>
    <div>
        <div class="col-12 indicator-container">
            <div class="row mb-4">
                <div class="col-md px-0 mr-3">
                    <div class="form-group mb-0">
                        <input 
                            type="text"
                            id="indicator-search"
                            ref="indicator_search"
                            class="form-control"
                            placeholder="Pesquisar por nome, telefone ou documento"
                            v-model="search"
                        >
                    </div>								
                </div>
                <div class="col-md-auto pl-md-0 px-0">
                    <button 	
                        class="btn btn-primary btn-block px-5"
                        @click="NewIndicator()"
                    >
                        Cadastrar
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 px-0">
            <DataTables
                :headers="headers"
                v-model="indicator_doctors"
                :search="search"
                :tr-button="true"
                @by-click-on-tr="EditIndicator"
            >
                <template v-slot:body="{ item }">
                    <td class="align-middle">
                        <b>{{ item.name.toUpperCase() }}</b>
                    </td>
                    <td class="align-middle">
                        {{ item.cpf || '-' }}
                    </td>
                    <td class="align-middle">
                        {{ item.crm || '-' }}
                    </td>
                    <td class="align-middle">
                        {{ item.phone || '-' }}
                    </td>
                    <td class="align-middle text-center">
                        <button
                            type="button"
                            class="btn btn-light-warning btn-sm mr-3"
                            @click.stop="EditIndicator(item)"
                        >
                            <i class="fa fa-pencil"></i>
                        </button>
                        <button
                            type="button"
                            class="btn btn-light-danger btn-sm"
                            @click.stop="DeleteButton(item)"
                        >
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </template>
            </DataTables>
        </div>
        <div class="modal fade" id="indicator_doctor_modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body p-4">
                        <div class="form-row align-items-center mb-3 d-flex justify-content-end">
                            <div class="col-md-auto px-0">
                                <button type="button" class="btn btn-light p-3" data-dismiss="modal">
                                    <i class="far fa-times mx-1"></i>
                                </button>
                            </div>
                        </div>
                        <IndicatorDoctorForm
                            ref="indicator_doctor"
                            :base_name="search"
                            :indicator="indicator"
                            @saved="IndicatorSaved"
                            @deleted="IndicatorDeleted"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DataTables from '../../shared/DataTables';
    import Swal from 'sweetalert2'
    import { mapGetters } from 'vuex'
    import IndicatorDoctorForm from '../../shared/IndicatorDoctorForm.vue'
    import Helpers from '../../../helpers'

    const _ = require('lodash')
    const helpers = new Helpers()

    export default {
        name: 'Indicator',
        components: {
            DataTables,
            IndicatorDoctorForm
        },
        props: 		{
			organization_id: 	{
				type: 		[String, Number],
				required: 	true,
				default: 	null
			}
		},
        data() {
            return {
                search: '',
                indicator_doctors: [],
                indicator: null,
                headers: [
                    { text: 'Nome', value: 'name', sortable: true },
                    { text: 'CPF', value: 'cpf' },
                    { text: 'CRM', value: 'crm' },
                    { text: 'Telefone', value: 'phone' },
                ]
            }
        },
        computed: {
            filteredDoctors() {
               if (!this.search) return this.indicator_doctors

                const search = helpers.RemoveAcento(this.search).toLowerCase()
                return this.indicator_doctors.filter(doctor => {
                    let allowed = false

                    if (doctor.name && helpers.RemoveAcento(doctor.name).toLowerCase().includes(search)) {
                        allowed = true
                    }

                    if (!allowed && doctor.cpf && helpers.RemoveAcento(helpers.SanitizedDocument(doctor.cpf)).toLowerCase().includes(helpers.SanitizedDocument(search))) {
                        allowed = true
                    }

                    if (!allowed && doctor.phone) {
                        let phone

                        try {
                            phone = JSON.parse(doctor.phone.replace(/\\/g, ''))
                        } catch (err) {
                            phone = []
                        }

                        if (Array.isArray(phone) && phone.length > 0) {
                            phone.forEach(tel => {
                                if (tel && tel.replace(/\D/g, '').indexOf(search) > -1) {
                                    allowed = true

                                    return
                                }
                            })
                        }
                    }

                    return allowed
                }) 
            }
        },
        methods: {
            OpenModal() {
                window.jQuery('#indicator_doctor_modal').modal('show')
            },
            CloseModal() {
                window.jQuery('#indicator_doctor_modal').modal('hide')
            },
            async GetIndicatorDoctors() {
                window.api.call('post', '/get-indicator-doctors', { 
                    account_id: this.organization_id
                })
                .then(({ data }) => {
                    if (data.response) {
                        this.indicator_doctors = _.sortBy(data.indicator_doctors, 'name');
                    }
                })
            },
            NewIndicator() {
                this.$refs.indicator_doctor.ClearForm(true)
                this.OpenModal()
            },
            EditIndicator(doctor) {
                this.indicator = doctor
                this.OpenModal()
            },
            IndicatorSaved() {
                this.GetIndicatorDoctors();

                this.search = ''

                this.CloseModal()
		    },
            IndicatorDeleted() {
                this.GetIndicatorDoctors();

                this.CloseModal()
            },
            DeleteButton(indicator) {
                Swal.fire({
                    title: 'Tem certeza?',
                    text: 'Esta ação não pode ser desfeita!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim, deletar',
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if(result.isConfirmed) {
                        this.$refs.indicator_doctor.Delete(indicator)
                    }
                })
            }
        },
        beforeMount() {
            this.GetIndicatorDoctors()
        }
    }
</script>

<style lang="css" scoped>
</style>