<template>
	<div class="bg-white container-fluid px-5 pb-5">
		<div class="row">
			<div class="col-12">
				<div>
					<table class="w-100" :style="{visibility: visibility}">
						<thead>
							<tr>
								<td>
									<div class="row">
										<div class="col-12">
											<div class="position-relative mb-3">
												<div v-if="account_logo" class="logo-container">
													<img :src="account_logo" style="height: 100px; position: absolute; top: 8px;">
												</div>
												<div class="logo-instant-container">
													<img :src="logo_instant" class="w-100" style="height: 44px">
												</div>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="position-relative">
									<table class="w-100 mb-5 main-content">
										<thead>
											<tr>
												<td>
													<div class="form-row mt-3 flex-nowrap text-dark align-items-start header-container" v-if="patient">
														<div class="col-auto mt-2">
															<div 
																class="shadow" 
																style="width: 165px; height: 155px; border: 1px solid #ccc; border-radius: .5rem;"
															>
																<img 
																	:src="patient_avatar" 
																	style="width: 100%; height: 100%; object-fit: cover; border-radius: .5rem;"
																>
															</div>
														</div>
														<div class="col ml-4 h-100 d-flex flex-column justify-content-between">
															<div class="row">
																<div class="col-12 px-0">
																	<span class="spotlight"><b> {{ patient.nome_paciente }} </b></span>
																</div>
															</div>
															<div class="row">
																<div class="col-auto px-0">
																	RG: <span :class="patient.rg ? 'font-weight-bold' : 'unknown'">{{ patient.rg ? patient.rg.toUpperCase() : 'DESCONHECIDO' }}</span>
																	<span v-if="patient.rg_uf != 'DESCONHECIDO'" :class="patient.rg_uf ? 'font-weight-bold' : 'unknown'">{{ patient.rg_uf ? patient.rg_uf.toUpperCase() : '' }}</span>
																</div>
																<div class="col-auto px-0">
																	CPF: <span :class="patient.cpf ? 'font-weight-bold' : 'unknown'">{{ patient.cpf ? patient.cpf.toUpperCase() : 'DESCONHECIDO' }}</span>
																</div>
																<div class="col-auto px-0">
																	SUS: <span :class="patient.sus ? 'font-weight-bold' : 'unknown'">{{ patient.sus ? patient.sus.toUpperCase() : 'DESCONHECIDO' }}</span>
																</div>
																<div class="col-auto px-0">
																	RH: <span :class="patient.rh ? 'font-weight-bold' : 'unknown'">{{ patient.rh ? patient.rh.toUpperCase() : 'DESCONHECIDO' }}</span>
																</div>
															</div>
															<div class="row">
																<div class="col-auto px-0">
																	DATA DE NASCIMENTO: <span :class="patient_birthdate ? 'font-weight-bold' : 'unknown'">{{ patient_birthdate }}</span>
																	<span v-if="patient.data_nascimento" :class="patient_age ? 'font-weight-bold' : 'unknown'"> ({{ patient_age }})</span>
																</div>
																<div class="col-auto px-0">
																	SEXO: <span :class="patient.sexo ? 'font-weight-bold' : 'unknown'">{{ patient.sexo ? (patient.sexo === 'm' ? 'MASCULINO' : 'FEMININO') : 'DESCONHECIDO' }}</span>
																</div>
																<div class="col-auto px-0">
																	TELEFONE: <b> {{ patient.telefone ?? 'DESCONHECIDO' }} </b>
																</div>
																<div v-if="is_endoscopy_modality && patient.nome_mae" 
																	class="col-auto px-0">
																	NOME MÃE: <b>{{ patient.nome_mae.toUpperCase() }}</b>
																</div>
															</div>
															<div class="row">
																<div v-if="scheduling.partnership && scheduling.partnership.nome" 
																	class="col-auto px-0">
																	CONVÊNIO: <b> {{ scheduling.partnership.nome.toUpperCase() }}</b>
																</div>
															</div>
															<div class="row">
																<div class="col-auto px-0" v-if="indicator_doctor && indicator_doctor.name">
																	SOLICITANTE: <b> {{ indicator_doctor.name.toUpperCase() }}</b>
																</div>
															</div>
														</div>
													</div>
													<div class="row">
														<div class="col-12">
															<hr style="border-top: 2px solid #333;" />
														</div>
													</div>
												</td>
											</tr>
										</thead>
										<tbody>
											<div v-if="with_logs == 'logs'">
												<tr 
													v-for="(log, index) in timeline_log"
													:key="`${log.user.name}${index}`"
												>
													<td>
														<div class="row">
															<div class="col-12">
																<span class="position-absolute ps-b-0 ps-t-0 ps-l-0 border-left bg-dark" style="margin-left: 38px;"></span>
																<div class="py-4 pl-6">
																	<span 
																		class="position-absolute ps-l-0 ml-4"
																		style="font-size: 3rem; margin-top: -28px" 
																		:class="{
																			'text-primary': index === (timeline_log.length - 1),
																			'text-secondary': index !== (timeline_log.length - 1)
																		}"
																	>●</span>
																	<div class="row">
																		<template v-if="log.status === 'Agendado'">
																			<div class="col-3">
																				<span class="text-dark font-14">
																					<b>{{log.status.toUpperCase()}}</b>
																				</span>
																			</div> 
																			<div class="col-9">
																				<span class="text-dark">
																					{{ FormatDate(log.data, 'DD/MM/YYYY HH:mm') }}
																				</span>
																			</div>
																			<template v-if="log.description">
																				<div class="col-3">
																					<span class="text-muted font-14">Descrição</span>
																				</div>
																				<div class="col-9">
																					<span class="text-dark">{{ log.description }}</span>
																				</div>
																			</template>
																			<div class="col-3">
																				<span class="text-muted font-14">Nome</span>
																			</div>
																			<div class="col-9">
																				<span class="text-dark">{{log.user.name}}</span>
																			</div>
																			<div class="col-3">
																				<span class="text-muted font-14">Procedimento</span>
																			</div>
																			<div class="col-9">
                                                    							<span class="text-dark">{{ log.proceeding ? log.proceeding : 'Sem procedimento vinculado'}}</span>
																			</div>
																		</template>
																		<template v-else>
																			<div class="col-3">
																				<span class="text-dark font-14">
																					<b>{{log.status.toUpperCase()}}</b>
																				</span>
																			</div> 
																			<div class="col-9">
																				<span class="text-dark">
																					{{ FormatDate(log.data, 'DD/MM/YYYY HH:mm') }}
																				</span>
																			</div>
																			<template v-if="log.logType === 'user' && log.status !== 'Espera' && log.status !== 'Falta'">
																				<template v-if="log.description">
																					<div class="col-3">
																						<span class="text-muted font-14">Descrição</span>
																					</div>
																					<div class="col-9">
																						<span class="text-dark">{{ log.description }}</span>
																					</div>
																				</template>
																				<div class="col-3">
																					<span class="text-muted font-14">Nome</span>
																				</div>
																				<div class="col-9">
																					<span class="text-dark">{{log.user.name}}</span>
																				</div>
																				<div class="col-3">
																					<span class="text-muted font-14">Procedimento</span>
																				</div>
																				<div class="col-9">
																					<span class="text-dark">{{ log.proceeding ? log.proceeding : 'Sem procedimento vinculado'}}</span>
																				</div>
																			</template>
																			<template v-else-if="log.status === 'Espera'">
																				<div class="col-3">
																					<span class="text-muted font-14">Nome</span>
																				</div>
																				<div class="col-9">
																					<span class="text-dark">INSTANT</span>
																				</div>
																			</template>
																			<template v-else-if="log.status === 'Falta'">
																				<div class="col-3">
																					<span class="text-muted font-14">Nome</span>
																				</div>
																				<div class="col-9">
																					<span class="text-dark">INSTANT</span>
																				</div>
																			</template>
																			<template v-else>
																				<div class="col-3">
																					<span class="text-muted font-14">Nome</span>
																				</div>
																				<div class="col-9">
																					<span class="text-dark">{{log.user.name}}</span>
																				</div>
																			</template>
																		</template>
																	</div>
																</div>
															</div>
														</div>
													</td>
												</tr>
											</div>
											<div v-else>
												<scheduling-card 
													v-if="journey_id"
													:journey_id="journey_id"
													:print_component="print_component"
													@update-indicator="UpdateIndicator"
												/>
											</div>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td>
									<footer>
										<div class="row position-relative">
											<div class="col-12" v-if="instance">
												<p class="text-center text-dark m-0">
													{{ instance.config.rodape_sup}}
												</p>
											</div>
											<div class="col-12" v-if="instance">
												<p class="text-center text-dark m-0">
													{{instance.config.rodape_inf}}
												</p>
											</div>
										</div>
									</footer>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const moment 		= require('moment')
	const _ 			= require('lodash')

	import { mapActions, mapGetters } from 'vuex'
	import Swal from 'sweetalert2'
	import SchedulingCard from './SchedulingCard'

	export default {

		name: 'PrintJourneyScheduling',
		props: {
			journey_id: 	{
				type: 			[String, Number],
				default: 		null,
				required: 		true
			},
			with_logs:		{
				type:			String,
				default:		null
			}
		},
		components: {
			SchedulingCard
		},
		data () {
			return {
				patient: 			null,
				scheduling: 		null,
				logo_instant: 		require('../../assets/img/header-impressao.png'),
				border_patient: 	require('../../assets/img/border-paciente.jpg'),
				default_avatar: 	window.defaultAvatar,
				visibility:  		'hidden',
				print_component: 	true,
				indicator_doctor:	{}
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id', 'account', 'requesters'
			]),
			current_link() {
				return window.location.href
			},
			instance() {
				return this.requesters.filter(r => parseInt(r.account_requester_id) === parseInt(this.requester_selected_id))[0].instance
			},
			account_logo() {
				return this.instance.config.logo_url
			},
			patient_avatar() {
				return this.patient && this.patient.avatar ? this.patient.avatar : this.default_avatar
			},
			patient_age() {            		
	            if(this.patient && this.patient.data_nascimento) {
	                const dob 		= moment(this.patient.data_nascimento, 'YYYY-MM-DD')
	                const today 	= moment()

	                const years 	= today.diff(dob, 'year')
	                dob.add(years, 'years')

	                const months 	= today.diff(dob, 'months')
	                dob.add(months, 'months')
	                
	                const days 		= today.diff(dob, 'days')

	                let result 		= ''

	                if(years > 0) {
	                   result =  `${years} ANO${years > 1 ? 'S' : ''}`

	                } else if (years === 0 && months > 0) {
	                    result = `${months} MES${months > 1 ? 'ES' : ''} e ${days} DIA${days > 1 ? 'S' : ''}`

	                } else if (months === 0 && days > 0) {
	                    result = `${days} DIA${days > 1 ? 'S' : ''}`
	                } else {
	                    result = '0 DIAS'
	                }
	                
	                return result
	            } else {
	                return 'DESCONHECIDO'
	            }
	    
			},
			timeline_log() {
	            if(this.scheduling && this.scheduling.logs && this.scheduling.logs.length > 0) {
	                const logs = this.scheduling.logs.slice()

	                return _.orderBy(logs, [(el => moment(el.data, 'YYYY-MM-DD HH:mm:ss'))], ['asc'])
	            } else {
	                return []
	            }
	        },
			first_log_date() {
	            if(this.timeline_log.length > 0) {
	                return this.FormatDate(_.last(this.timeline_log).data, 'DD/MM/YYYY')
	            } else {
	                return ''
	            }
	        },
			is_endoscopy_modality() {
				const ENDOSCOPY_MODALITY_ID = 27

				return this.scheduling?.proceedings?.filter(p => p.modality_id == ENDOSCOPY_MODALITY_ID).length > 0 ?? false
			},
			
			patient_birthdate() {
				let birthdate = 'DESCONHECIDO';

				if(!this.patient.data_nascimento) return birthdate

				birthdate = moment(this.patient.data_nascimento).format('DD/MM/YYYY')

				return birthdate
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetScheduling()
			{
				this.StartLoading()

				return window.api.call('post', '/get-journeys-schedulings', {
						account_id: 		this.requester_selected_id,
						journey_id:			this.journey_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.scheduling 		= data.journeys[`${this.journey_id}`][0]
                			this.patient 			= this.scheduling.journey.patient

							this.scheduling.partnership	= this.scheduling.proceeding.partnerships ? this.scheduling.proceeding.partnerships[0] : null

							if (this.patient.telefone)
							{
								if (Array.isArray(JSON.parse(this.patient.telefone)))
								{
									this.patient.telefone    = JSON.parse(this.patient.telefone)[0]
								}
							}

							this.indicator_doctor = this.scheduling.indicator_doctor

							// if(this.patient.doc_id.tipo.indexOf('CPF') > -1) {
							// 	this.patient.cpf = this.patient.doc_id.substring(4)
							// } else if(this.patient.doc_id.tipo.indexOf('RG') > -1) {
							// 	this.patient.rg = this.patient.doc_id.substring(3)
							// } else if(this.patient.doc_id.tipo.indexOf('SUS') > -1)  {
							// 	this.patient.sus = this.patient.doc_id.substring(4)
							// } else {
							// 	this.patient.rh = this.patient.doc_id.substring(3)
							// }

						}else{

							Swal.fire({
		                        icon:   'error',
		                        text:   'Houve um erro ao obter, contate o suporte.',
		                        title:  'Opss...'
		                    })
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			FormatDate(value, fmt)
			{
	            const m 		= moment(value, 'YYYY-MM-DD HH:mm:ss')

	            return m.format(fmt)
	        },
			UpdateIndicator(param) {
				this.indicator_doctor = param
			},
		},
		mounted()
		{
			const vm = this

	        window.addEventListener('afterprint', () => {
	            if (window.mainWindow){
	                window.mainWindow.postMessage({print: true})
	            }
	        })

			vm.GetScheduling()
				.finally( async () => {
					await vm.$nextTick()
	                await vm.$nextTick()
	                await vm.$nextTick()
	                setTimeout(async () => {
	                    vm.visibility = 'visible'
	                    await vm.$nextTick()
	                    window.print()
	                }, 1000)
				})

			const style_print = window.jQuery('<link>', {
				rel: 'stylesheet',
				type: 'text/css',
				href: require('@/assets/sass/portrait.scss')
			});
			
			style_print.appendTo('head');
		}
	}
</script>

<style lang="css" scoped>
	.row {
		gap: 0px 20px;
	}

	.unknown {
		opacity: .5;
	}

	.qrcode {
		position: absolute;
		bottom: 0px;
		right: 0px;
	}

	.spotlight {
		font-size: 1.75rem;
	}

	.logo-instant-container {
		position: absolute;
		top: 68px;
		width: 100%;
	}
	
	.header-container {
		height: 161px;
	}

	.main-content {
		position: absolute;
		top: 95px;
	}

	table {
		page-break-after: auto;
	}

	table thead {
		display: table-header-group;
	}

	table tfoot  {
		display: table-footer-group;
	}

	@media print {
		body {
			width: 21cm;
			height: 29.7cm;
			margin: 0 !important;
			padding: 0 !important;
			overflow: hidden;
			background-color: red;
		}

		html, body {
			height:100vh; 
			margin: 0 !important; 
			padding: 0 !important;
			overflow: hidden;
		}

		@page  {
			margin: 2mm 4mm 10mm 4mm;
		}

		footer {
			padding: 0 35px;
			position: fixed;
			right: 0;
			left: 0;
			bottom: 0;
		}
	}
</style>