<template>
    <div class="vh-md-80 d-flex flex-column align-items-center justify-content-between">
        <div class="col-8 col-md-12">
            <div class="d-block mx-auto pb-2 pb-md-3 text-center max-500">
                <h4 class="font-24 font-md-32 m-0">Gerenciar Pacientes</h4>
            </div>
        </div>

        <div class="col-12 col-sm-10 col-md-8 col-xxl-6 mt-5">
            <div class="form-row align-items-center pb-2">
                <div class="col">
                    <div class="form-group m-0 position-relative">
                        <input 
                            type="search" 
                            v-debounce:500="DelayGetPatients"
                            class="form-control pl-5" 
                            placeholder="Buscar por Nome, CPF, RG ou RH"
                            v-model.trim="search"
                        >
                        <i 
                            class="far fa-search font-14 position-absolute ps-l-0 ml-3 ps-v-center"
                        ></i>

                    </div>
                </div>
                <div class="col-auto">
                    <button 
                        class="btn btn-block btn-primary"
                        data-toggle="modal" 
                        data-target="#patient-modal"
                    >
                        Cadastrar
                    </button>
                </div>
            </div>
        </div>

        <div class="col-12 col-xl-11 col-xxl-8 mt-2 mt-md-5">
            <DataTables
                v-model="patients"
			    type="search"
                height="600px"
                :headers="headers"
                :search="search"
                :tr-button="true"
                :scrollUpdate="scrollUpdate"
                :offset="offset"
                :total="total"
                :footer_style="{
                    'display': 'flex',
                    'justify-content': 'end'
                }"
                @load-table="UpdateTable"
                @by-click-on-tr="EditPatient"
            >
                <template v-slot:body="{ item }">
                    <td class="align-middle" scope="col">
                        <b>{{ item.nome_paciente.toUpperCase() }}</b>
                    </td>
                    <td class="text-muted align-middle" scope="col">
                        {{ ParseCPF(item.cpf) || '-' }}
                    </td>
                    <td class="text-muted align-middle" scope="col">
                        {{ (item.rg && item.rg_uf ? item.rg + item.rg_uf : '-') }}
                    </td>
                    <td class="text-muted align-middle" scope="col">
                        {{ item.sus || '-' }}
                    </td>
                    <td class="text-muted align-middle" scope="col">
                        {{ item.rh || '-' }}
                    </td>
                    <td class="text-muted align-middle" scope="col">
                        {{ ParseDate(item.created_at) }}
                    </td>
                    <td scope="col" class="align-middle">
                        <span 
                            class="badge font-16 p-2"
                            :class="{
                                'badge-secondary': 	item.desconhecido,
                            }"
                        >
                            {{ item.desconhecido ? 'desconhecido' : ''}}
                        </span>
                    </td>
                </template>
            </DataTables>
        </div>
        <!-- <div class="col-12 col-md-8 mb-3 ml-4">
            <button 
                class="btn btn-light px-md-5 my-5 align-self-start"
                @click="Previous"
            >
                voltar
            </button>
        </div> -->
        <div 
			class="modal fade" 
			id="patient-modal"
			data-backdrop="static"
		>
		  	<div class="modal-dialog modal-xl">
			    <div class="modal-content">
					<PatientForm
						form_type="standard"
                        :edit="true"
						@exit="New"
					>
						<template v-slot:actions="{ Save }">
							<button 
								class="btn btn-light px-md-5"
								data-dismiss="modal"
							>
								fechar
							</button>
							<button 
								class="btn btn-primary px-md-5"
								@click="Save"
							>
								salvar <i class="far fa-save ml-4"></i>
							</button>
						</template>
					</PatientForm>
				</div>
			</div>
		</div>
    </div>
</template>

<script>

const _         = require('lodash')
const moment    = require('moment')

import DataTables from '../shared/DataTables'
import PatientForm from '../shared/PatientForm'
import Swal from 'sweetalert2'

import { mapActions, mapGetters } from 'vuex'

export default {
    name:  'Patients',
    components: {
        DataTables,
        PatientForm
    },
    props: {

    },
    data() {
        return {
            patients:       [],
            search:         '',
            limit:          200,
            offset:         0,
            total:          0,
            total_filtered: 0,
            form_type:      'pre',
            headers: [
                { text: 'Nome', value: 'nome_paciente', sortable: true },
                { text: 'CPF', value: 'cpf' },
                { text: 'RG', value: 'rg' },
                { text: 'SUS', value: 'sus' },
                { text: 'RH', value: 'rh' },
                { text: 'Data de cadastro', value: 'created_at', sortable: true },
            ],
            scrollUpdate:   true,
            try_search: false,
        }
    },
    computed: {
        ...mapGetters('auth', [
            'requester_selected_id'
        ]),
    },
    watch: {
        search: {
            handler(val) {
                this.try_search = false
            }
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        Previous()
        {
            this.$router.push({ name: 'Home' })
        },
        ParseCPF(value) {
            if(value) {
                let cpf = ''
    
                cpf = value.replace(/[^\d]/g, "");
                
                return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            }

            return ''
        },
        ParseDate(date)
        {
            let data = date.substr(0, 10)

            data = data.split('-').reverse().join('/')

            return data
        },
        EditPatient(patient)
        {
            this.$router.push({ name: 'PatientPanel', params: {
                patient_id: patient.id
            }})
        },
        async GetPatients(clear = true) {
            const vm = this

            vm.StartLoading()

            vm.offset = clear ? 0 : vm.offset

            vm.patients = clear ? [] : vm.patients

            return await window.api.call('post', '/get-patients', {
                account_id: `${vm.requester_selected_id}`,
                offset: vm.offset,
                limit: vm.limit,
                search: vm.search
            })
                .then(({data}) => {
                    if(data.response) {
                        vm.total = data.total
                        vm.total_filtered = data.total_filtered

                        const mapped_patients = data.patients.map(p => {
                            return {
                                ...p,
                                cpf: p.cpf == 'DESCONHECIDO' ? null : p.cpf,
                                rg: p.rg == 'DESCONHECIDO' ? null : p.rg,
                                rg_uf: p.rg_uf == 'DESCONHECIDO' ? null : p.rg_uf,
                                sus: p.sus == 'DESCONHECIDO' ? null : p.sus,
                                rh: !p.rh ? null : p.rh
                            }
                        })
                        
                        const ordered_patients = _.orderBy(mapped_patients, ['desconhecido', 'nome_paciente'], ['asc', 'asc'])

                        vm.patients         = vm.patients.length ? [ ...vm.patients, ...ordered_patients ] : ordered_patients
                    }
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        async DelayGetPatients() {
            const vm = this

            setTimeout(async () => {
                if(!vm.try_search) {
                    await vm.GetPatients(true)
                    
                    vm.try_search = true
                }
            }, 1000)
        },
        New(patient)
        {
            this.patients.push(patient)

            this.search 			= patient.nome_paciente.toLowerCase()

            window.jQuery('#patient-modal').modal('hide')
                .on('hidden.bs.modal', () => {
                    window.jQuery('body').removeClass('modal-open')
                })
        },
        async UpdateTable(param) {
            const vm = this

            const Toast = Swal.mixin({
                title: 				"Não há mais pacientes a serem carregados.",
                toast: 				true,
                position: 			'top',
                icon:				'info',
                timer:				4000,
                timerProgressBar:	true
            })

            if(vm.search) {
                if(vm.patients.length >= vm.total_filtered) {
                    Toast.fire();

                    return
                }
            } else {
                if(vm.patients.length >= vm.total) {
                    Toast.fire()

                    return
                }
            }

            vm.offset = param.offset

            await vm.GetPatients(false)
        },
    },
    async mounted()
    {
        await this.GetPatients()

        window.jQuery('#patient-modal').on('shown', () => {
            window.jQuery('body').addClass('modal-open')
        })
    }
}
</script>

<style lang="css" scoped>
@media (min-width: 1800px) {
    .col-xxl-8 {
        max-width: 66.66666667%;
    }

    .col-xxl-6 {
        max-width: 50%;
    }
}

</style>