import { render, staticRenderFns } from "./ProceedingForm.vue?vue&type=template&id=c3ba8cd2&scoped=true"
import script from "./ProceedingForm.vue?vue&type=script&lang=js"
export * from "./ProceedingForm.vue?vue&type=script&lang=js"
import style0 from "./ProceedingForm.vue?vue&type=style&index=0&id=c3ba8cd2&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3ba8cd2",
  null
  
)

export default component.exports