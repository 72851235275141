var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 px-0 d-flex flex-wrap align-items-start"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"col px-0 mr-0 mb-4 d-flex flex-nowrap align-items-center justify-content-start"},[_c('div',{staticClass:"pl-0 mr-2 col-auto"},[_c('div',{staticClass:"font-32",class:{
                            'text-primary':         _vm.invoice_scheduling,
                            'text-secondary':       !_vm.invoice_scheduling
                        },on:{"click":_vm.ToggleInvoiceScheduling}},[_c('i',{staticClass:"fad",class:{
                                'fa-toggle-on':     _vm.invoice_scheduling,
                                'fa-toggle-off':    !_vm.invoice_scheduling
                            }})])]),_c('label',{staticClass:"mb-0",on:{"click":_vm.ToggleInvoiceScheduling}},[_vm._v(" Faturamento no Agendamento ")])]),_vm._m(0)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"col px-0 mr-0 mb-4 d-flex flex-nowrap align-items-center justify-content-start"},[_c('div',{staticClass:"pl-0 mr-2 col-auto"},[_c('div',{staticClass:"font-32",class:{
                            'text-primary':         _vm.indicator_doctor,
                            'text-secondary':       !_vm.indicator_doctor
                        },on:{"click":_vm.ToggleIndicatorDoctor}},[_c('i',{staticClass:"fad",class:{
                                'fa-toggle-on':     _vm.indicator_doctor,
                                'fa-toggle-off':    !_vm.indicator_doctor
                            }})])]),_c('label',{staticClass:"mb-0",on:{"click":_vm.ToggleIndicatorDoctor}},[_vm._v(" Solicitante Obrigatório ")])]),_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 px-0"},[_c('p',{staticClass:"text-start"},[_vm._v(" Habilite esta opção para realizar o faturamento dos procedimentos no momento do agendamento quando for pronto atendimento ou encaixe, e na confirmação quando for pré-agendamento. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 px-0"},[_c('p',{staticClass:"text-start"},[_vm._v(" Habilite esta opção para que o solicitante seja obrigatório. ")])])
}]

export { render, staticRenderFns }