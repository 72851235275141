<template>
	<div class="container-large"
		:class="!printStatement ? 'pb-4' : 'pb-1'">
		<transition name="fade" mode="out-in">
			<div 
				v-if="step === 'pre'" 
				:key="step"
			>
				
			</div>
			<div 
				v-if="step === 'module'" 
				:key="step"
				class="pb-3"
			>
				<Modules 
					:label="'Encaixe'"
					type="strict"
					:module_id="scheduling.module_id"
					@next="WalkStep"
				/>
			</div>
			<div 
				v-if="step === 'modality'"
				:key="step"
				class="pb-3"
			>
				<Modalities 
					:label="'Encaixe'"
					type="strict"
					:module_id="scheduling.module_id"
					:modality_id="scheduling.modality_id"
					@next="WalkStep"
					@previous="step = 'module'"
				/>
			</div>
			<div 
				v-if="step === 'proceeding-and-partnership'"
				:key="step"
				class="pb-3"
			>
				<ProceedingAndPartnership 
					:label="'Encaixe'"
					:modality_id="scheduling.modality_id"
					:partnership="scheduling.partnership"
					:proceedings="scheduling.proceedings"
					@next="WalkStep"
					@previous="step = 'modality'"
				/>
			</div>
			<div 
				v-if="step === 'schedules'"
				:key="step"
				class="pb-3"
			>
				<Schedules 
					:label="'Encaixe'"
					type="fit"
					:modality_id="scheduling.modality_id"
					:proceedings="scheduling.proceedings"
					:schedules="scheduling.schedules"
					@next="WalkStep"
					@previous="step = 'proceeding-and-partnership'"
				/>
			</div>
			<div 
				v-if="step === 'patient'"
				:key="step"
				class="pb-3"
			>
				<Patient 
					:label="'Encaixe'"
					:patient="scheduling.patient"
					:form_type="'standard'"
					@next="WalkStep"
					@previous="step = 'schedules'"
				/>
			</div>
			<div 
				v-if="step === 'authorization-and-guide'"
				:key="step"
				class="pb-3"
			>
				<AuthorizationAndGuide 
					:label="'Agendamento'"
					:classification="scheduling.classification"
					:authorization="scheduling.authorization"
					:guide="scheduling.guide"
					@next="WalkStep"
					@previous="step = 'patient'"
				/>
			</div>
			<div 
				v-if="step === 'confirm'"
				:key="step"
				:class="!printStatement ? 'pb-3' : 'pb-1'"
			>
				<Confirm 
					:label="'Encaixe'"
					type="fit"
					:module_id="scheduling.module_id"
					:modality_id="scheduling.modality_id"
					:partnership="scheduling.partnership"
					:proceedings="scheduling.proceedings"
					:time="scheduling.time"
					:patient="scheduling.patient"
					:classification="scheduling.classification"
					:authorization="scheduling.authorization"
					:guide="scheduling.guide"
					:printStatement="printStatement"
					:indicator_doctor="scheduling.indicator_doctor"
					@indicator-doctor="SetIndicatorDoctor"
					ref="confirm"
					@next="WalkStep"
					@previous="step = 'authorization-and-guide'"
				/>
			</div>
		</transition>
	</div>
</template>

<script>
	const _ 		= window._
	import Modules from './components/Modules'
	import Modalities from './components/Modalities'
	import ProceedingAndPartnership from './components/ProceedingAndPartnership'
	import Schedules from './components/Schedules'
	import Patient from './components/Patient'
	import AuthorizationAndGuide from './components/AuthorizationAndGuide'
	import Confirm from './components/Confirm'

	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'FitWizard',
		components: 	{
			Modules,
			Modalities,
			ProceedingAndPartnership,
			AuthorizationAndGuide,
			Schedules,
			Patient,
			Confirm
		},
		props: {
			fit_scheduling: 	{
				type: 			Object,
				default:		null,
			}
		},
		data () {
			return {
				step: 			'pre',
				scheduling: 	{
					module_id: 			null,
					modality_id: 		null,
					schedules: 			[],
					proceedings: 		[],
					partnership: 		null,
					place: 				null,
					time: 				null,
					patient: 			null,
					classification: 	1,
					authorization: 		'',
					guide: 				'',
					obs: 				'',
					indicator_doctor:	null,
					payment_info:		null,
				},
				printStatement:			false
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id'
			])
		},
		watch: 	{
			step: {
				handler(val)
				{
					let hash 	= ''

					switch(val)
					{
						case 'module':
							hash = '#modulo'
						break
						case 'modality':
							hash = '#modalidade'
						break
						case 'proceeding-and-partnership':
							hash = '#convenio-e-procedimentos'
						break
						case 'schedules':
							hash = '#horarios'
						break
						case 'authorization-and-guide':
							hash = '#classificacao-e-autorizacao'
						break
						case 'patient':
							hash = '#paciente'
						break
						case 'confirm':
							hash = '#confirmacao'
						break
					}

					window.location.hash 	= hash
				},
				immediate: true
			},
			$route(to)
			{
				switch(to.hash)
				{
					case '#modulo':
						this.step = 'module'
					break
					case '#modalidade':
						this.step = 'modality'
					break
					case '#convenio-e-procedimentos':
						this.step = 'proceeding-and-partnership'
					break
					case '#horarios':
						this.step = 'schedules'
					break
					case '#paciente':
						this.step = 'patient'
					break
					case '#classificacao-e-autorizacao':
						this.step = 'authorization-and-guide'
					break
					case '#confirmacao':
						this.step = 'confirm'
					break
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading', 'GetModulesAndModalities'
			]),
			async WalkStep(param = null)
			{
				const vm 				= this
				const day_of_week 		= new Date().getDay()

				switch(this.step)
				{
					case 'pre':

						this.scheduling.module_id 		= null
						this.scheduling.modality_id 	= null
						this.scheduling.proceedings 	= []
						this.scheduling.schedules 		= []
						this.scheduling.partnership 	= null
						this.scheduling.time 			= null
						this.scheduling.patient 		= null
						this.scheduling.place 			= null
						this.scheduling.authorization	= ''
						this.scheduling.classification 	= 1
						this.scheduling.guide 			= ''
						this.scheduling.obs 			= ''

						this.step 						= 'module'

					break
					case 'module':

						this.scheduling.module_id 		= param.id

						this.scheduling.modality_id 	= null
						this.scheduling.proceedings 	= []
						this.scheduling.schedules 		= []
						this.scheduling.partnership 	= null
						this.scheduling.time 			= null
						this.scheduling.place 			= null
						this.scheduling.patient 		= null
						this.scheduling.authorization	= ''
						this.scheduling.classification 	= 1
						this.scheduling.guide 			= ''
						this.scheduling.obs 			= ''

						this.step 						= 'modality'

					break
					case 'modality':

						this.scheduling.modality_id 	= param.id
						this.scheduling.proceedings 	= []
						this.scheduling.schedules 		= []
						this.scheduling.partnership 	= null
						this.scheduling.time 			= null
						this.scheduling.place 			= null
						this.scheduling.patient 		= null
						this.scheduling.authorization	= ''
						this.scheduling.classification 	= 1
						this.scheduling.guide 			= ''
						this.scheduling.obs 			= ''

						this.step 						= 'proceeding-and-partnership'

					break
					case 'proceeding-and-partnership':

						this.scheduling.proceedings 	= param.proceedings
						this.scheduling.partnership 	= param.partnership
						this.scheduling.time 			= null
						this.scheduling.patient 		= null
						this.scheduling.place 			= null
						this.scheduling.authorization	= ''
						this.scheduling.classification 	= 1
						this.scheduling.guide 			= ''
						this.scheduling.obs 			= ''
						
						this.scheduling.schedules 		=  _.orderBy(_.filter(param.schedules, schedule => {

							let dias_habilitados 	= JSON.parse(schedule.dias_habilitados)

							let allowed 			= false

							if(dias_habilitados)
							{
								if(schedule.ciclica)
								{
									allowed 	= dias_habilitados.find( dia => dia.value == day_of_week && dia.selected)

									allowed 	= typeof allowed === 'object'

								}else{

									let today 	= new Date()
									today 		= `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`

									allowed 	= _.findIndex(dias_habilitados, { 'fulldate': today }) > -1
								}
							}

							return !schedule.continua && schedule.status && allowed

						}), 'nome')


						if(this.scheduling.schedules.length > 0)
						{
							this.step 						= 'schedules'

						}else{

							Swal.fire({	
								title: 	'Eita!',
								text: 	'Não há agendas disponiveis para hoje.',
								icon: 	'error'
							})
						}
						

					break
					case 'schedules':

						this.scheduling.time 			= param
						this.scheduling.patient 		= null
						this.scheduling.authorization	= ''
						this.scheduling.classification 	= 1
						this.scheduling.guide 			= ''
						this.scheduling.place 			= null
						this.scheduling.obs 			= ''

						this.step 						= 'patient'

					break
					case 'patient':

						this.scheduling.patient 		= param
						this.scheduling.authorization	= ''
						this.scheduling.classification 	= 1
						this.scheduling.guide 			= ''
						this.scheduling.place 			= null
						this.scheduling.obs 			= ''

					
						this.step 						= 'authorization-and-guide'

					break
					case 'authorization-and-guide':

						this.scheduling.authorization	= param.authorization
						this.scheduling.classification 	= param.classification
						this.scheduling.guide 			= param.guide
						this.scheduling.obs 			= ''
						this.scheduling.place 			= null

						this.step 						= 'confirm'

					break
					case 'confirm':

						this.scheduling.obs 			= param.obs
						this.scheduling.place 			= param.place

						this.scheduling.payment_info 	= param.payment_methods_info
						
						await this.SaveFit()					

					break
				}
			},
			SaveFit()
			{
				const vm 	= this
				this.StartLoading()
				
				return window.api.call('post', '/save-fit', {
						...this.scheduling,
						account_id: 	this.requester_selected_id
					})
					.then(({data}) => {
						if(data.response)
						{
							vm.scheduling.journey_id = data.journey
							vm.printStatement = true

							Swal.fire({
								icon: 				'question',
								title: 				'Deseja imprimir o dados do encaixe?',
								showConfirmButton: 	true,
								showCancelButton: 	true,
								confirmButtonText: 	'Sim',
								cancelButtonText: 	'Não',
								width: 				'auto',
								customClass: 		{
									confirmButton: 		'success',
								},
							}).then(async (res) => {
								if (res.isConfirmed)
								{
									vm.PrintTimeline()

									vm.printStatement = false

									vm.step 						= 'pre'

									vm.WalkStep()

									Swal.fire({	
										title: 	'Concluido !',
										html: 	`Paciente encaixado com sucesso.<br>ID: ${data.journey}`,
										icon: 	'success'
									})

								}else{

									vm.step 						= 'pre'

									vm.WalkStep()

									Swal.fire({
										title: 	'Concluido !',
										html: 	`Paciente encaixado com sucesso.<br>ID: ${data.journey}`,
										icon: 	'success'
									})
								}
							})

						}else{

							Swal.fire({
								icon: 	'error',
								title: 	'Opss...',
								text: 	'Houve um erro ao concluir, contate o suporte.'
							})
						}	
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			PrintTimeline()
			{
				const vm  = this
				
				const fn  = (event) => {
					if (event.data.print)
					{
						window.removeEventListener('message', fn)

						win.close()
					}
				}

				const win     	= window.open(`/pesquisa/${vm.scheduling.journey_id}/imprimir`, '_blank')
				win.mainWindow  = window
				window.addEventListener('message', fn)
			},
			SetIndicatorDoctor(doctor) {
				this.scheduling.indicator_doctor = doctor
			}
		},
		async beforeMount()
		{
			await this.GetModulesAndModalities()


			this.WalkStep()
		}
	}
</script>

<style lang="css" scoped>
</style>