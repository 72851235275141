<template>
	<div class="col-12 d-flex flex-column align-items-center justify-content-center">
		<div class="col-12 px-0 col-lg-8">
			<div class="col-12">
				<div class="col-12 px-0 print-d-none d-block pb-4 pb-md-5 text-md-center pr-6 pr-md-0">
					<span> {{ label }} </span>
					<h4 class="font-24 font-md-32 m-0">Confira as informações</h4>
				</div>
				<div class="print-d-block text-center">
					<div class="mb-5">
						<img :src="account_logo" style="height: 100px; position: relative; top: 8px;">
					</div>
					<h5>{{ label }}</h5>
					<h3>{{ requester_selected_object.requester.name }}</h3>
				</div>
			</div>
			<scheduling-card
				:journey="journey"
				@update-obs="FillObs"
			>
			</scheduling-card>
			<div class="col-12 text-center pb-4 py-md-5 mt-4 mt-md-0 print-d-none">
				<div class="d-flex flex-wrap justify-content-end justify-content-md-between">
					<button
						class="btn btn-light px-md-5"
						@click="Previous"
					>
						voltar
					</button>
					<div class="">
						<button v-if="indicator_doctor == null"
							class="btn btn-light px-md-5"
							data-toggle="modal"
							@click="OpenModal('#indicator_doctor_modal')"
							:class="{'border-danger': attemptedSubmit && !indicator_doctor}"
						>
							solicitante
						</button>
						<div v-else>
							<b>Solicitante:</b>
							<br>
							{{ indicator_doctor.name }}
							<br>
							<span id="remove-indicator-doctor"
								class="text-danger"
								@click="SetIndicatorDoctor(null)">
								Remover
								<i class="fa fa-trash"></i>
							</span>
						</div>
					</div>
					<button v-if="time.schedule?.continua || type == 'fit'"
						class="btn btn-light px-md-5"
						@click="OpenModal('#payment-methods-modal')"
					>
						pagamento
					</button>
					<button
						class="btn btn-primary px-md-5"
						@click="Continue"
					>
						{{['scheduling', 'rescheduling'].indexOf(type) > -1 ? 'agendar' : 'encaixar' }} <i class="far fa-calendar-edit ml-3"></i>
					</button>
				</div>
			</div>
		</div>

		<IndicatorDoctorModal
			@indicator_doctor="SetIndicatorDoctor"
		/>

		<PaymentMethodsModal
			v-if="time.schedule?.continua || type == 'fit'"
			:total="total_proceedings"
			@payment-methods-added="PaymentMethodsHandler"
		/>
	</div>
</template>

<script>
	import Helpers from '../../../helpers'
	import { mapGetters, mapActions } from 'vuex'
	import IndicatorDoctorModal from './IndicatorDoctorModal.vue'
	import PaymentMethodsModal from './PaymentMethodsModal.vue'
	import SchedulingCard from '../../search/SchedulingCard.vue'
	import moment from 'moment'
	import Swal from 'sweetalert2'

	export default {

		name: 'Confirm',
		components: {
			IndicatorDoctorModal,
			PaymentMethodsModal,
			SchedulingCard
		},
		props: 	{
			label: 			{
				type: 			String,
				default: 		''
			},
			module_id: 	 	{
				type: 			[String, Number],
				default: 		null
			},
			modality_id: 	{
				type: 			[String, Number],
				default: 		null
			},
			journey_id: 	{
				type: 			[String, Number],
				default: 		null
			},
			patient: 		{
				type: 			Object,
				default: 		null
			},
			partnership:	{
				type: 			Object,
				default: 		null
			},
			time:	{
				type: 			Object,
				default: 		null
			},
			proceedings: 	{
				type: 			Array,
				default: 		null
			},
			classification: {
				type: 			[Number, String],
				default: 		''
			},
			authorization: 	{
				type: 			[Number, String],
				default: 		''
			},
			guide: 			{
				type: 			[Number, String],
				default: 		''
			},
			indicator_doctor: {
				type:			Object,
				default:		() => {}
			},
			type: 			{
				type: 			String,
				default: 		'scheduling'
			},
			printStatement:	{
				type:			Boolean,
				default:		false
			}
		},
		data () {
			return {
				places: [],
				form: 	{
					place_id: 		null,
					telefone_1: 	'',
					obs: 			''
				},
				payment_methods_info:	null,
				attemptedSubmit: false,
				defaultAvatar:		window.defaultAvatar
			}
		},
		watch: 	{
			patient: 	{
				handler(val)
				{
					if(val.telefone)
					{
						const telefone_1 		= JSON.parse(val.telefone.replace(/\\/g,''))[0]

						this.form.telefone_1 	= telefone_1
					}

				},
				immediate: true,
				deep: true
			}
		},
		computed: {
			...mapGetters('system', [
				'modules', 'modalities'
			]),
			...mapGetters('auth', [
				'requester_selected_object',
				'requester_selected_id'
			]),
			selected_place_name()
			{
				const place 	= this.places.find( p => p.id === this.form.place_id )

				return place ? place.endereco : 'Não há'
			},
			selected_module()
			{
				const module 	= this.modules.find( m => m.id === parseInt(this.module_id))

				return module.name
			},
			selected_modality()
			{
				const modality 	= this.modalities.find( m => m.id === parseInt(this.modality_id))

				return modality.name
			},
			account_logo()
			{
				const vm = this
				
				return vm.requester_selected_object.instance.config.logo_url
			},
			has_instruction() {
				const vm = this
				
				return vm.proceedings.filter(p => p.preparo).length
			},
			patient_age()
			{            		
	            if (this.patient && this.patient.data_nascimento)
	            {
	                const dob 		= moment(this.patient.data_nascimento, 'YYYY-MM-DD')
	                const today 	= moment()

	                const years 	= today.diff(dob, 'year')
	                dob.add(years, 'years')

	                const months 	= today.diff(dob, 'months')
	                dob.add(months, 'months')
	                
	                const days 		= today.diff(dob, 'days')


	                let result 		= ''

	                if (years > 0)
	                {
	                   result =  `${years} ANO${years > 1 ? 'S' : ''} `

	                }else if (years === 0 && months > 0)
	                {
	                    result = `${months} MES${months > 1 ? 'ES' : ''} e ${days} DIA${days > 1 ? 'S' : ''}`

	                }else if (months === 0 && days > 0)
	                {
	                    result = `${days} DIA${days > 1 ? 'S' : ''} `

	                }else{

	                    result = '0 DIAS'
	                }
	                
	                return result

	            }else{

	                return 'DESCONHECIDO'
	            }
			},
			journey() {
				const vm = this

				const schedulings = vm.proceedings.map(proceeding => { 
					return { 
						proceeding
					}
				})

				const obj = {
					schedulings,
					module: 			vm.selected_module,
					modality: 			vm.selected_modality,
					indicator_doctor:	vm.indicator_doctor,
					patient:			{ ...vm.patient }
				}

				const schedule = vm.time.schedule ? { ...vm.time.schedule } : { ...vm.time }

				obj.schedulings[0] = {
					...obj.schedulings[0],
					schedule,
					tipo: 				vm.type == "fit" ? 3 : vm.time.tipo,
					data:				vm.time.dia,
					hora:				vm.time.hora,
					obs:				vm.form.obs,
					partnerships: 		[],
				}

				obj.schedulings[0].partnerships[0] = {
					...vm.partnership
				}
				
				return obj
			},
			total_proceedings() {
				let value = 0

				if(!this.proceedings.length) return value

				value = this.proceedings.reduce((acc, proceeding) => {
					return acc + Number(proceeding?.partnerships[0]?.pivot?.valor)
				}, 0)

				return value
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			FormatFullDate(value, fmt)
			{
				const m   = moment(value, 'YYYY-MM-DD HH:mm:ss')

				return m.format(fmt)
			},
			Previous()
			{
				this.$emit('previous')
			},
			GetAvatar(path)
			{
				return path ? path : '/img/profile.png'
			},
			GetClassification()
			{
				const classification = window.classifications.find( c => c.value == parseInt(this.classification))
				return classification.text
			},
			GetPlaces()
			{
				this.StartLoading()

				return window.api.call('post', '/get-organization-places', {
						organization_id: 	this.requester_selected_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.form.place_id 	= null
							this.places 		= data.places
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			Continue() {
				if(this.CheckIndicatorDoctorState() && !this.indicator_doctor) {
					this.attemptedSubmit = true

					Swal.fire({
							icon: 'error',
                            title: 'Solicitante Obrigatório',
							text: 'É obrigatório selecionar um solicitante antes de continuar!',
                            showConfirmButton: true
                        })

						return
				}

				const place = this.places.find(p => p.id == this.form.place_id)

				this.$emit('next', {
					obs: 	this.form.obs,
					place: 	place,
					indicator_doctor: this.indicator_doctor,
					payment_methods_info: this.payment_methods_info
				})
			},
			Print()
			{
				window.print()
			},
			HideIndicatorDoctorModal() {
				window.jQuery('#indicator_doctor_modal').modal('hide')
				window.jQuery('body').removeClass('modal-open')
			},
			SetIndicatorDoctor(doctor) {
				this.$emit('indicator-doctor', doctor)
				
				this.HideIndicatorDoctorModal()
			},
			FillObs(obs) {
				this.form.obs = obs
			},
			OpenModal(id) {
				window.jQuery(id).modal('show')
			},
			PaymentMethodsHandler(payment_methods_info) {
				this.payment_methods_info = payment_methods_info
			},
			CheckIndicatorDoctorState() {
				return this.requester_selected_object.instance.config.indicator_doctor
			}
		},
		mounted()
		{
			const style_print = window.jQuery('<link>', {
				rel: 'stylesheet',
				type: 'text/css',
				href: require('@/assets/sass/portrait.scss')
			});
			
			style_print.appendTo('head');

			this.GetPlaces()
		}
	}
</script>

<style lang="css" scoped>
	.patient-button {
		width: 160px;
		height: 160px;
	}

	.patient-button i {
		font-size: 3rem;
	}

	@media print
	{
	    .print-d-none,
	    .overlay-loading,
	    .swal2-container
	    {
	        display: none !important;
	    }

	    .text-dark,
	    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6
	    {
	    	color: #212529 !important;
	    }

		.container hr {
			margin: 0.2rem 0;
		}

	    button
	    {
	    	display: none !important;
	    }

	    body
	    {
	    	background-color: #fff !important;
	    	color: #212529 !important;
	    }

	    body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown)>[aria-hidden=true],
	    .print-d-block
	    {
	        display: block !important;
	    }

	    header
	    {
	        display: none !important;
	    }
	}

	.p-m-0 p
	{
		margin: 0;
	}

	#remove-indicator-doctor {
		cursor: pointer;
	}

	.border-danger {
		border: 2px solid red;
	}
</style>