<template>
	<div 
		class="modal fade" 
		id="modal-proceeding"
		data-backdrop="static"
	>
	  	<div class="modal-dialog modal-xl">
		    <div class="modal-content">
		    	<div class="container-fluid">
					<div class="row align-items-center justify-content-between">
						<div class="col-12">
							<div class="d-block mx-auto pt-5 text-center max-350">
								<span>Procedimentos</span>
								<h4 class="font-24 font-md-32 m-0">
									{{ type == 'config' ? 'Configurar' : 'Novo' }} procedimento
								</h4>
							</div>
						</div>
						<div class="col-12">
							<div class="scroll-x scroll-transparent d-flex mx--5 mx-md-0">
								<ul 
									class="nav nav-pills nav-theme w-md-100 justify-content-md-center my-4 my-md-5 px-5"
									:class="{
										'd-none': type == 'new'
									}"
								>
									<li class="nav-item">
										<a 
											class="nav-link pb-md-4 px-md-4 active" 
											data-toggle="tab" 
											href="#info"
										>
											Dados
										</a>
									</li>
									<li 
										class="nav-item"
										v-if="type == 'config'"
									>
										<a 
											class="nav-link pb-md-4 px-md-4" 
											data-toggle="tab" 
											href="#partnerships"
										>
											Convênios
										</a>
									</li>
									<li 
										class="nav-item"
										v-if="type == 'config'"
									>
										<a 
											class="nav-link pb-md-4 px-md-4" 
											data-toggle="tab" 
											href="#schedules"
										>
											Agendas
										</a>
									</li>
								</ul>
							</div>
							<div class="tab-content">
								<div id="info" class="tab-pane active">
									<div class="col">
										<div class="row">
											<div :class="module_id == 1 ? 'col-md-8' : 'col-12'">
												<div class="form-group">
													<label>Nome</label>
													<input 
														type="text" 
														v-model="$v.form.name.$model"
														class="form-control"
														:class="{ 'is-invalid': $v.form.name.$error }"
														ref="name"
													>
													<div class="invalid-feedback" v-if="!$v.form.name.required">
														Obrigatório
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="form-group">
													<label>Modalidade DICOM</label>
													<select 
														v-model="form.dicom_modality_id"
														class="form-control"
														ref="dicom_modality_id"
													>
														<option :value="null">
															( Nenhuma )
														</option>
														<option
															v-for="dicom_modality in dicom_modalities"
															:key="dicom_modality.id"
															:value="dicom_modality.id"
														>
															{{ dicom_modality.identifier }} - {{ dicom_modality.description }}
														</option>
													</select>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-md-4">
												<div class="form-group">
													<label>Código Interno</label>
													<input 
														type="text" 
														v-model="form.internal_code"
														class="form-control"
													>
												</div>
											</div>
											<div v-if="!this.is_vacancy" class="col-12 col-md-4">
												<div class="form-group">
													<label>Duração</label>
													<select 
														v-model="$v.form.duration.$model"
														class="form-control"
														:class="{ 'is-invalid': $v.form.duration.$error }"
														ref="duration"
													>
														<option
															v-for="minute in minutes"
															:key="minute.value"
															:value="minute.value"
														>
															{{ minute.text }}min
														</option>
													</select>
													<div class="invalid-feedback" v-if="!$v.form.duration.required">
														Obrigatório
													</div>
												</div>
											</div>
											<div v-else class="col-12 col-md-4">
												<div class="form-group">
													<label>Vagas</label>
													<input 
														type="tel"
														class="form-control"
														:class="{ 'is-invalid': $v.form.vacancys.$error }"
														v-model="$v.form.vacancys.$model"
														ref="vacancys"
													>
													<div class="invalid-feedback" v-if="!$v.form.vacancys.required">
														Obrigatório
													</div>
													<div class="invalid-feedback" v-else-if="!$v.form.vacancys.betweenValue">
														Deve ser entre 1 e 10
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="form-group">
													<label>Valor base do procedimento</label>
													<money
														v-model="form.base_value"
														class="form-control"
													/>
												</div>
											</div>
										</div>
										<div class="row px-1">
											<fieldset class="col mx-3 mb-3 px-3">
												<legend>Alíquota</legend>
												<div class="row justify-content-end align-items-center">
													<div class="col-md">
														<div class="form-group">
															<label>Tipo</label>
															<select 
																v-model="form.aliquot_type"
																class="form-control"
															>
																<option :value="1">Valor</option>
																<option :value="2">Porcentagem</option>
															</select>
														</div>
													</div>
													<div class="col-md">
														<div class="form-group">
															<label>Porcentagem</label>
															<input
																type="number"
																:disabled="form.aliquot_type === 1"
																v-model="form.aliquot_percentage"
																min="0"
																class="form-control"
															>
														</div>
													</div>
													<div class="col-md">
														<div class="form-group">
															<label>Valor da alíquota</label>
															<money
																:disabled="form.aliquot_type === 2"
																v-model="form.aliquot_value"
																class="form-control"
															/>
														</div>
													</div>
												</div>
											</fieldset>
										</div>
										<div class="row px-1">
											<fieldset class="col mx-3 mb-3 px-3">
												<legend>Honorário</legend>
												<div class="row justify-content-end align-items-center">
													<div class="col-md">
														<div class="form-group">
															<label>Tipo</label>
															<select 
																v-model="form.honorary_type"
																class="form-control"
															>
																<option :value="1">Valor</option>
																<option :value="2">Porcentagem</option>
															</select>
														</div>
													</div>
													<div class="col-md">
														<div class="form-group">
															<label>Porcentagem</label>
															<input
																type="number"
																:disabled="form.honorary_type === 1"
																v-model="form.honorary_percentage"
																min="0"
																class="form-control"
															>
														</div>
													</div>
													<div class="col-md">
														<div class="form-group">
															<label>Valor do honorário</label>
															<money
																:disabled="form.honorary_type === 2"
																v-model="form.honorary_value"
																class="form-control"
															/>
														</div>
													</div>
												</div>
											</fieldset>
										</div>
										<!-- <div class="row">
											<div class="col-12">
												<Autocomplete
													label="Código TUSS"
													:items="tuss"
													item-text="terminologia"
													item-value="id"
													v-model="form.tuss"
												/>
											</div>
										</div> -->
										<div class="row">
											<div class="col-12">
												<div class="form-group">
													<label>Descrição</label>
													<textarea
														rows="5"
														class="form-control"
														v-model="form.description"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="partnerships" class="tab-pane">
									<div class="col d-flex justify-content-center" v-if="proceeding">
										<div class="col-md-9">
											
											<div class="row mb-4 justify-content-end align-items-center">
												<div class="col-md">						
													<h4 class="font-24 mt-3 mb-0">{{ proceeding.nome }}</h4>
													<p class="font-14">Configure o valor base e os convênios deste procedimento</p>
												</div>
												<div class="col-md">
													<div class="form-group">
														<label>Valor base do procedimento</label>
														<money
															v-model="form.base_value"
															class="form-control"
														/>
													</div>
												</div>
											</div>
											<div
												v-for="partnership in partnerships"
												:key="partnership.id"
												:class="{
													'opacity-50': !partnership.enabled
												}"
											>
												<div class="d-flex align-items-start">
													<h5 class="mb-3">{{ partnership.nome }}</h5>
													<div 
														class="form-group m-0 ml-3 form-check"
													>
														<input 
															type="checkbox" 
															:id="`part${partnership.id}`" 
															
															class="bg-danger"
															v-model="partnership.enabled"
														>
														<label 
															:for="`part${partnership.id}`"
														></label>
													</div>
												</div>
												<fieldset>
													<legend>Procedimento</legend>
													<div class="row justify-content-end align-items-center">
														<div class="col-md">
															<div class="form-group">
																<label>Tipo</label>
																<select 
																	v-model="partnership.tipo_valor"
																	class="form-control"
																	:disabled="!partnership.enabled"
																>
																	<option :value="1">Valor</option>
																	<option :value="2">Porcentagem</option>
																</select>
															</div>
														</div>
														<div class="col-md">
															<div class="form-group">
																<label>Porcentagem</label>
																<input 
																	type="number" 
																	:disabled="!partnership.enabled || partnership.tipo_valor === 1"
																	v-model="partnership.porcentagem" 
																	min="0" 
																	class="form-control"
																>
															</div>
														</div>
														<div class="col-md">
															<div class="form-group">
																<label>Valor do procedimento</label>
																<money 
																	:disabled="!partnership.enabled || partnership.tipo_valor == 2"
																	v-model="partnership.valor"
																	class="form-control"
																/>
															</div>
														</div>
													</div>
												</fieldset>
												<fieldset>
													<legend>Alíquota</legend>
													<div class="row justify-content-end align-items-center">
														<div class="col-md">
															<div class="form-group">
																<label>Tipo</label>
																<select 
																	v-model="partnership.aliquot_type"
																	class="form-control"
																	:disabled="!partnership.enabled"
																>
																	<option :value="1">Valor</option>
																	<option :value="2">Porcentagem</option>
																</select>
															</div>
														</div>
														<div class="col-md">
															<div class="form-group">
																<label>Porcentagem</label>
																<input
																	type="number"
																	:disabled="!partnership.enabled || partnership.aliquot_type === 1"
																	v-model="partnership.aliquot_percentage"
																	min="0"
																	class="form-control"
																>
															</div>
														</div>
														<div class="col-md">
															<div class="form-group">
																<label>Valor da alíquota</label>
																<money
																	:disabled="!partnership.enabled || partnership.aliquot_type === 2"
																	v-model="partnership.aliquot_value"
																	class="form-control"
																/>
															</div>
														</div>
													</div>
												</fieldset>
												<fieldset>
													<legend>Honorário</legend>
													<div class="row justify-content-end align-items-center">
														<div class="col-md">
															<div class="form-group">
																<label>Tipo</label>
																<select 
																	v-model="partnership.honorary_type"
																	class="form-control"
																	:disabled="!partnership.enabled"
																>
																	<option :value="1">Valor</option>
																	<option :value="2">Porcentagem</option>
																</select>
															</div>
														</div>
														<div class="col-md">
															<div class="form-group">
																<label>Porcentagem</label>
																<input
																	type="number"
																	:disabled="!partnership.enabled || partnership.honorary_type === 1"
																	v-model="partnership.honorary_percentage"
																	min="0"
																	class="form-control"
																>
															</div>
														</div>
														<div class="col-md">
															<div class="form-group">
																<label>Valor do honorário</label>
																<money
																	:disabled="!partnership.enabled || partnership.honorary_type === 2"
																	v-model="partnership.honorary_value"
																	class="form-control"
																/>
															</div>
														</div>
													</div>
												</fieldset>
												<hr/>
											</div>
										</div>
									</div>
								</div>
								<div id="schedules" class="tab-pane">
									<div class="col d-flex justify-content-center">
										<div class="col-md-9">
											<table class="table table-custom font-14">
												<thead>
													<tr>
														<th 
															width="16"
															class="text-muted" 
															scope="col"
														>									
															<div 
																class="form-group m-0 form-check"
															>
																<input 
																	type="checkbox" 
																	id="all"
																	v-model="check_all"
																>
																<label for="all"></label>
															</div>
														</th>
														<th class="text-muted" scope="col">Configure os vinculos com as agendas</th>
													</tr>
												</thead>
												<tbody>
													<tr
														v-for="schedule in schedules"
														:key="schedule.id"
													>
														<td class="align-middle">
															<div class="form-group m-0 form-check">
																<input 
																	type="checkbox" 
																	:id="`select${schedule.id}`" 
																	:value="schedule.id"
																	class="bg-danger"
																	v-model="form.selected_schedules"
																>
																<label 
																	:for="`select${schedule.id}`"
																></label>
															</div>
														</td>
														<td class="align-middle">
															<b>{{ schedule.nome }}</b>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 text-center pt-5 pb-3">
							<div class="d-flex justify-content-between">
								<button 
									class="btn btn-light px-md-5"
									@click="Close"
								>
									fechar
								</button>
								<button 
									class="btn btn-primary px-md-5"
									@click="Save"
								>
									salvar <i class="far fa-save ml-3"></i>
								</button>
							</div>
						</div>
					</div>
				</div>	   
		        
		    </div>
		</div>
	</div>
</template>

<script>
	const _ 		= require('lodash')
	import { required, requiredIf, between, betweenValue } from 'vuelidate/lib/validators'
	import Swal from 'sweetalert2'
	import { mapActions, mapGetters } from 'vuex'
	export default {

		name: 'ProceedingForm',
		props: 	{
			open: 			{
				type: 			Boolean,
				default: 		false
			},
			proceeding: 	{
				type: 			Object,
				default: 		null
			},
			parent_id: 		{
				type: 			[String, Number],
				default: 		null
			},
			module_id:		{
				type:			[String, Number],
				default:		null
			},
			modality_id: 	{
				type: 			[String, Number],
				default: 		null
			},
			type: 			{
				type: 			String,
				default: 		'new' //'config'
			},
			is_vacancy:		{
				type:			Boolean,
				default:		false
			}
		},
		data () {
			return {
				modal: false,
				check_all: false,
				tuss: [],
				partnerships: [],
				schedules: [],
				form: 			{
					name: null,
					internal_code: null,
					dicom_modality_id: null,
					duration: 5,
					vacancys: 1,
					base_value: 0.00,
					description: null,
					tuss: null,
					selected_schedules: [],
					selected_partnerships: [],
					aliquot_type: 2, // PERCENTAGE
					aliquot_percentage: 0, 
					aliquot_value: 0.00, 
					honorary_type: 2, // PERCENTAGE
					honorary_percentage: 0, 
					honorary_value: 0.00, 
				}
			}	
		},
		validations() {
			return {
				form: 		{
					name: 		{
						required
					},
					duration: 	{
						required: requiredIf(() => {
							return !this.is_vacancy
						})
					},
					vacancys: {
						required: requiredIf(() => {
							return this.is_vacancy
						}),
						between: between(1, 10)
					}
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id', 'requesters'
			]),
			...mapGetters('system', [
				'dicom_modalities'
			]),
			sum_percent_of_partnerships() {
				let tmp 	= 0

				this.partnerships.forEach(p => {
					tmp += p.porcentagem + p.tipo_valor
				})

				return tmp 
			},
			sum_percent_of_partnerships_aliquots() {
				let tmp 	= 0

				this.partnerships.forEach(p => {
					tmp += p.aliquot_percentage + p.aliquot_value
				})

				return tmp 
			},
			sum_percent_of_partnerships_honoraries() {
				let tmp 	= 0

				this.partnerships.forEach(p => {
					tmp += p.honorary_percentage + p.honorary_value
				})

				return tmp 
			},
			minutes() {
                let arr         = []
                let min         = 5

                for (let i = 0; i < 100; i++) //500 MINUTOS
                {
                    arr.push({
                        text:   `${min.toString().padStart(2, '0')}`,
                        value:  min
                    })

                    min     = min + 5
                }

                return arr            
			},
		},
		watch: {
			async open(val) {
				if(val && !_.isEmpty(this.proceeding)) {
					if(this.type == 'config') {
						await this.GetSchedulesAndPartnershipsWithProceeding()
					}

					this.form.name = this.proceeding.nome
					this.form.internal_code = this.proceeding.codigo_interno
					this.form.dicom_modality_id = this.proceeding.dicom_modality_id
					this.form.duration = this.proceeding.duracao
					this.form.vacancys = this.proceeding.duracao / 5
					this.form.base_value = parseFloat(this.proceeding.valor_base)
					this.form.description = this.proceeding.preparo
					this.form.tuss = { id: this.proceeding.tuss_id }

					this.form.aliquot_type = parseInt(this.proceeding.aliquot_type)
					this.form.aliquot_percentage = parseFloat(this.proceeding.aliquot_percentage)
					this.form.aliquot_value = parseFloat(this.proceeding.aliquot_value)

					this.form.honorary_type = parseInt(this.proceeding.honorary_type)
					this.form.honorary_percentage = parseFloat(this.proceeding.honorary_percentage)
					this.form.honorary_value = parseFloat(this.proceeding.honorary_value)
				} else {
					this.form.name 				= null
					this.form.internal_code 	= null
					this.form.dicom_modality_id = null
					this.form.duration 			= 5
					this.form.vacancys 			= 1
					this.form.base_value 		= 0.00
					this.form.description 		= null
					this.form.tuss 				= null
					
					this.form.aliquot_type = 2
					this.form.aliquot_percentage = 0
					this.form.aliquot_value = 0.00

					this.form.honorary_type = 2
					this.form.honorary_percentage = 0
					this.form.honorary_value = 0.00
				}

				window.jQuery('#modal-proceeding').modal( val ? 'show' : 'hide')
			},
			check_all: {
				handler(val) {
					if(val) {
						this.schedules.forEach(schedule => {
							if (!this.form.selected_schedules.includes(schedule.id)) {
								this.form.selected_schedules.push(schedule.id)
							}
						})

					} else {
						this.form.selected_schedules = []
					}
				}
			},
			sum_percent_of_partnerships: {
				handler() {
					this.UpdatePartnershipValue()
				}
			},
			sum_percent_of_partnerships_aliquots: {
				handler() {
					this.UpdatePartnershipAliquotValue()
				}
			},
			sum_percent_of_partnerships_honoraries: {
				handler() {
					this.UpdatePartnershipHonoraryValue()
				}
			},
			'form.base_value': {
				handler() {
					this.UpdateBaseAliquotValue()
					this.UpdateBaseHonoraryValue()

					this.UpdatePartnershipValue()
				}
			},
			'form.aliquot_percentage': {
				handler() {
					this.UpdateBaseAliquotValue()
				}
			},
			'form.honorary_percentage': {
				handler() {
					this.UpdateBaseHonoraryValue()
				}
			},
			'form.vacancys': {
				handler(val) {
					if(val > 0 && val < 11) {
						const MINIMAL_BLOCK_SIZE = 5

						this.form.duration = val * MINIMAL_BLOCK_SIZE
					}
				}
			}
		},
		methods: 	{
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			UpdateBaseAliquotValue() {
				const TYPE_PERCENTAGE = 2

				if(this.form.aliquot_type === TYPE_PERCENTAGE) {
					this.form.aliquot_value = parseFloat(this.form.base_value) * (parseFloat(this.form.aliquot_percentage) / 100)
				}
			},
			UpdateBaseHonoraryValue() {
				const TYPE_PERCENTAGE = 2

				if(this.form.honorary_type === TYPE_PERCENTAGE) {
					this.form.honorary_value = parseFloat(this.form.base_value) * (parseFloat(this.form.honorary_percentage) / 100)
				}
			},
			UpdatePartnershipValue() {
				if(this.type == 'config') {
					this.partnerships 		= this.partnerships.map(p => {
						const TYPE_PERCENTAGE = 2

						if(p.tipo_valor == TYPE_PERCENTAGE) {
							p.valor = parseFloat(this.form.base_value) * (parseFloat(p.porcentagem) / 100)
						}

						return p
					})

					this.UpdatePartnershipAliquotValue()
					this.UpdatePartnershipHonoraryValue()
				}
			},
			UpdatePartnershipAliquotValue() {
				if(this.loading_data) return;

				if(this.type == 'config') {
					this.partnerships 		= this.partnerships.map(p => {
						const TYPE_PERCENTAGE = 2
						
						if(p.aliquot_type === TYPE_PERCENTAGE) {
							p.aliquot_value =  parseFloat(p.valor) * (parseFloat(p.aliquot_percentage) / 100)
						}

						return p
					})
				}
			},
			UpdatePartnershipHonoraryValue() {
				if(this.loading_data) return;

				if(this.type == 'config') {
					this.partnerships 		= this.partnerships.map(p => {
						const TYPE_PERCENTAGE = 2
						
						if(p.honorary_type === TYPE_PERCENTAGE) {
							p.honorary_value =  parseFloat(p.valor) * (parseFloat(p.honorary_percentage) / 100)
						}

						return p
					})
				}
			},
			async GetSchedulesAndPartnershipsWithProceeding() {
				this.StartLoading()

				this.loading_data = true;

				this.schedules 					= []
				this.partnerships 				= []
				this.form.selected_schedules 	= []
				this.form.selected_partnerships = []
				this.check_all 					= false

				return await window.api.call('post', '/get-schedules-and-partnerships-with-proceeding',{
						proceeding_id: 	this.proceeding.id,
						account_id: 	this.requester_selected_id,
						modality_id: 	this.proceeding.modality_id,
					})
					.then(({data}) => {
						if(data.response) {
							this.form.selected_schedules = []

							data.schedules.forEach(s => {
								if(s.proceedings.length > 0) {
									this.form.selected_schedules.push(s.id)
								}
							})

							this.schedules = _.sortBy(data.schedules, s => {
								return this.form.selected_schedules.includes(s.id) ? -1 : 1
							})

							if(this.form.selected_schedules.length === data.schedules.length) {
								this.check_all 	= true
							}

							this.partnerships = _.sortBy(data.partnerships.map(p => {
								let obj 	=  {
									id: p.id,
									nome: p.nome,
									enabled: false,
									tipo_valor: 2,
									porcentagem: 100,
									valor: 0.00,
									aliquot_type: 2,
									aliquot_percentage: 100,
									aliquot_value: 0.00,
									honorary_type: 2,
									honorary_percentage: 100,
									honorary_value: 0.00,
								}

								if(p.proceedings.length) {
									obj.enabled = true

									obj.tipo_valor = parseInt(p.proceedings[0].pivot.tipo_valor)
									obj.porcentagem = parseFloat(p.proceedings[0].pivot.porcentagem)
									obj.valor = parseFloat(p.proceedings[0].pivot.valor)

									obj.aliquot_type = parseInt(p.proceedings[0].pivot.aliquot_type)
									obj.aliquot_percentage = parseFloat(p.proceedings[0].pivot.aliquot_percentage)
									obj.aliquot_value = parseFloat(p.proceedings[0].pivot.aliquot_value)

									obj.honorary_type = parseInt(p.proceedings[0].pivot.honorary_type)
									obj.honorary_percentage = parseFloat(p.proceedings[0].pivot.honorary_percentage)
									obj.honorary_value = parseFloat(p.proceedings[0].pivot.honorary_value)
								}

								return obj
							}), p => !p.enabled)
						}
					})
					.finally(() => {
						this.FinishLoading()

						this.loading_data = false;
					})
			},
			GetTUSS() {
				this.StartLoading()

				return window.api.call('get', '/get-tuss')
					.then(({data}) => {
						if(data.response)
						{
							this.tuss 		= data.tuss
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			Close() {
				this.$emit('close')
			},
			async Save() {
				this.$v.form.$touch()

				if(!this.$v.form.$invalid) {
					this.StartLoading()

					let obj 	= {
						...this.form,
						type: 				3, // PROCEDIMENTO
						account_id: 		this.requester_selected_id,
						modality_id: 		this.modality_id,
						parent_id: 			this.parent_id
					}

					if(obj.selected_schedules.length > 0) {
						obj.schedules 	= this.schedules.filter( p => {
							return obj.selected_schedules.indexOf(p.id) > -1
						})
					}			

					if(this.partnerships.length > 0) {
						const partnerships 	= this.partnerships.filter( p => p.enabled )

						if(partnerships.length > 0) {
							obj.partnerships 	= partnerships
						}
					}

					if(obj.tuss) {
						obj.tuss_id 	= obj.tuss.id
					}

					delete obj.tuss
					delete obj.selected_schedules
					delete obj.selected_partnerships

					if(this.type == 'new') {
						delete obj.id
					} else {
						obj.id 		= this.proceeding.id
					}

					await window.api.call('post', '/save-proceeding', obj)
						.then(({data}) => {
							if(data.response) {
								Swal.fire({
									icon: 		'success',
									title: 		'Procedimento salvo com sucesso.',
									position: 	'top',
									timer:		5000,
									toast: 		true,
									timerProgressBar: true
								})

								this.$emit('refresh', data.proceeding)

								this.Close()
							} else {
								Swal.fire({
									icon: 		'error',
									title: 		'Opss...',
									text: 		'Houve um erro ao concluir, contate o suporte.'
								})
							}
						})
						.finally(() => {
							this.FinishLoading()
						})
				} else {
					window.helpers.focusInvalid(this.$v.form, this)
				}
			}
		},
		async beforeMount() {
			await this.GetTUSS()
		}
	}
</script>

<style lang="css" scoped>
.tab-content {
	max-height: 500px;
	overflow: auto;
}

fieldset {
	border: 1px solid var(--dark-light);
    border-radius: .5rem;
	padding: .1rem 1rem;
}

legend {
	display: inline;
	width: unset;
    padding: 0 10px;
	font-size: 1rem;
}
</style>